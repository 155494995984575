

import React from 'react'
import  {motion} from "framer-motion"
import { Link } from "react-router-dom";

const SacBeton = ({setIsOpenContantPage,content}) => {
  return (
	<motion.div 
      initial={{ translateX: "-100%"}}
      animate={{ translateX: "0"}}
      transition={{duration: 0.5}}
      className='w-screen 	  '>
               <div className='mt-24 w-full h-16 bg-yellow-400 sticky top-[88px] z-50 mb-32'>
        <nav aria-label="breadcrumb" className="w-full p-4 ">
	<ol className="flex h-8 space-x-2">
		<li className="flex items-center">
      <Link to="/">
			<a rel="noopener noreferrer" href="#" title="Back to homepage" className="hover:underline">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5 pr-1 dark:text-gray-400">
					<path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"></path>
				</svg>
			</a>
      </Link>
		</li>
    <Link to="/elektrik-malzemeleri" className='flex items-center space-x-2'> 
			<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" aria-hidden="true" fill="currentColor" className="w-2 h-2 mt-1 transform rotate-90 fill-current dark:text-gray-600">
				<path d="M32 30.031h-32l16-28.061z"></path>
			</svg>
			<a rel="noopener noreferrer" href="#" className="flex items-center px-1 capitalize hover:underline font-medium">Elektrik Malzemeleri</a>
    </Link>
		<li className="flex items-center space-x-2">
			<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" aria-hidden="true" fill="currentColor" className="w-2 h-2 mt-1 transform rotate-90 fill-current dark:text-gray-600">
				<path d="M32 30.031h-32l16-28.061z"></path>
			</svg>
			<a rel="noopener noreferrer" href="#" className="flex items-center px-1 capitalize hover:underline font-medium">{content?.TrafoMerkezi?.[0]}</a>
		</li>

	</ol>
</nav>
</div>
    <section className=" text-gray-900  ">
	<div className="container  p-6 pb-12 mx-auto space-y-24 lg:px-8 lg:max-w-7xl">
		<div>
			<h2 className="text-3xl font-bold tracki text-center sm:text-5xl text-yellow-400 drop-shadow-[0_1.2px_1.2px_rgba(0,0,0,0.8)]">{content?.TrafoMerkezi?.[0]}</h2>
      <div className="grid lg:gap-8 lg:grid-cols-2 lg:items-center">
			<div>
				<h3 className="text-2xl font-bold tracki sm:text-3xl text-yellow-400 drop-shadow-[0_1.2px_1.2px_rgba(0,0,0,0.8)]  mt-16  ">{content?.TrafoMerkezi?.[1]}</h3>
        <p className='max-w-3xl mx-auto my-4 text-xl  text-gray-700'>{content?.TrafoMerkezi?.[2]}</p>
        <div className=' '>
        <table className="border-collapse  text-center  mb-12 lg:text-xl text-sm mx-auto w-full  ">
          <thead className=''>
            <tr >
              <th colSpan={2} class="border border-slate-300 ...">{content?.TrafoMerkezi?.[3]}</th>
            </tr>
          </thead>
          <tbody>
            <tr className='p-4'>
              <td class="border border-slate-300 ...">{content?.TrafoMerkezi?.[4]}</td>
              <td class="border border-slate-300 ...">12-36kV</td>
            </tr>
            <tr>
              <td class="border border-slate-300 ...">{content?.TrafoMerkezi?.[5]}  	</td>
              <td class="border border-slate-300 ...">1600kVA</td>
            </tr>
            <tr>
              <td class="border border-slate-300 ...">{content?.TrafoMerkezi?.[6]}</td>
              <td class="border border-slate-300 ...">16kA 1s</td>
            </tr>
            <tr>
              <td class="border border-slate-300 ...">{content?.TrafoMerkezi?.[7]}	</td>
              <td class="border border-slate-300 ...">AIS/GIS</td>
            </tr>
        
          </tbody>
        </table>
        </div>
				</div>
        <div className='md:h-[300px] md:w-[500px] h-[150px] w-[250px] my-auto   mx-auto border border-yellow-400'>
                <img src="/images/elektrik/sac/0.jpg" alt=""  className='w-full h-full mx-auto'/>
              </div>
			</div>
		</div>
	
      
      <div className="grid lg:gap-8 lg:grid-cols-2 lg:items-center">
      <div className='md:h-[300px] md:w-[500px] h-[150px] w-[250px] my-auto   mx-auto border border-yellow-400'>
                <img src="/images/elektrik/sac/1.jpg" alt=""  className='w-full h-full mx-auto'/>
              </div>
			<div>
				<h3 className="text-2xl font-bold tracki sm:text-3xl text-yellow-400 drop-shadow-[0_1.2px_1.2px_rgba(0,0,0,0.8)] mt-12 md:mt-0 ">{content?.TrafoMerkezi?.[8]}</h3>
        <p className='max-w-3xl mx-auto my-4 text-xl  text-gray-700'>{content?.TrafoMerkezi?.[9]}</p>
        <table className="border-collapse border border-yellow-400 text-center  mb-12 lg:text-xl text-sm  w-full ">
          <thead className=''>
            <tr >
              <th colSpan={2} class="border border-slate-300 ...">{content?.TrafoMerkezi?.[3]}</th>
            </tr>
          </thead>
          <tbody>
            <tr className='p-4'>
              <td class="border border-slate-300 ...">{content?.TrafoMerkezi?.[4]}</td>
              <td class="border border-slate-300 ...">12-36kV</td>
            </tr>
            <tr>
              <td class="border border-slate-300 ...">{content?.TrafoMerkezi?.[5]}  	</td>
              <td class="border border-slate-300 ...">1600kVA</td>
            </tr>
            <tr>
              <td class="border border-slate-300 ...">{content?.TrafoMerkezi?.[6]}	</td>
              <td class="border border-slate-300 ...">16kA 1s</td>
            </tr>
            <tr>
              <td class="border border-slate-300 ...">{content?.TrafoMerkezi?.[7]}	</td>
              <td class="border border-slate-300 ...">AIS/GIS</td>
            </tr>
        
          </tbody>
        </table>
				</div>
        <div>
				<h3 className="text-2xl font-bold tracki sm:text-3xl text-yellow-400 drop-shadow-[0_1.2px_1.2px_rgba(0,0,0,0.8)] ">{content?.TrafoMerkezi?.[10]}</h3>
        <p className='max-w-3xl mx-auto my-4 text-xl  text-gray-700'>{content?.TrafoMerkezi?.[11]}</p>
        <table className="border-collapse border border-yellow-400 text-center  mb-12 lg:text-xl text-sm  w-full ">
          <thead className=''>
            <tr >
              <th colSpan={2} class="border border-slate-300 ...">{content?.TrafoMerkezi?.[3]}</th>
            </tr>
          </thead>
          <tbody>
            <tr className='p-4'>
              <td class="border border-slate-300 ...">{content?.TrafoMerkezi?.[4]}</td>
              <td class="border border-slate-300 ...">12-36kV</td>
            </tr>
            <tr>
              <td class="border border-slate-300 ...">{content?.TrafoMerkezi?.[5]}</td>
              <td class="border border-slate-300 ...">1600kVA</td>
            </tr>
            <tr>
              <td class="border border-slate-300 ...">{content?.TrafoMerkezi?.[6]}	</td>
              <td class="border border-slate-300 ...">16/20kA 1s</td>
            </tr>
            <tr>
              <td class="border border-slate-300 ...">{content?.TrafoMerkezi?.[7]}	</td>
              <td class="border border-slate-300 ...">AIS/GIS</td>
            </tr>
        
          </tbody>
        </table>
   
				</div>
        <div className='md:h-[300px] md:w-[500px] h-[150px] w-[250px] my-auto   mx-auto border border-yellow-400'>
                <img src="/images/elektrik/sac/2.jpg" alt=""  className='w-full h-full mx-auto'/>
              </div>
			</div>






		</div>

    
    
</section>

<div className='py-12'>
          <center>
            <button 
                	onClick={()=>{
                    setIsOpenContantPage(true)
                  }}type="button" className="px-8 py-3 bg-gray-800 text-yellow-400 font-semibold border rounded border-gray-700  hover:bg-yellow-400 hover:text-gray-800  transition-all duration-200">{content?.ElectricalMaterial?.[8]}</button>
          </center>
      </div>
    </motion.div>
  )
}

export default SacBeton