import React from 'react'
import  {motion} from "framer-motion"
import { Link } from "react-router-dom";

const ElektrikMalzemeleri = ({setIsOpenContantPage,content}) => {
	const container= {
		visible: {
			transition :{
				delayChildren: 0.2,
				staggerChildren: 0.1
			}
		}
	}

	const item = {
		hidden: {
			opacity:0,
			translateY :20
		},
		visible: {
			opacity:1,
			translateY:0
		}
	}
  return (
    <div className='w-screen mt-24'><section className="bg-white text-gray-100">
		<div className="p-6 py-12  bg-yellow-400 text-gray-900">
	<div className=" mx-auto">
		<div className=" flex flex-col lg:flex-row items-center justify-between mx-24">
			<h2 className="text-center md:text-3xl text-2xl tracki font-bold">{content?.Home?.HeroTitles?.[1]}
			</h2>
	

			<a 
			onClick={()=>{
				setIsOpenContantPage(true)
			}}
			rel="noreferrer noopener" className="cursor-pointer px-5 mt-4 lg:mt-0 py-3 rounded-md border block bg-gray-900 text-white hover:bg-gray-800  border-gray-400">{content?.ElectricalMaterial?.[10]}</a>
		</div>
	</div>
</div>
	<div className="container max-w-6xl p-6 mx-auto space-y-6 sm:space-y-12">
		
		<motion.div 
			initial="hidden"
			animate="visible"
			variants={container}
		className="grid justify-center grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-4">
			<motion.a 
				variants={item}
			rel="noopener noreferrer" href="#" className="w-[256px] mx-auto group hover:no-underline focus:no-underline bg-gray-900">
				<Link to="/elektrik-malzemeleri/og">
				<img role="presentation" className="object-cover w-full rounded h-44 bg-gray-500 " src="/images/elektrik/og/main.jpg" />
				<div className="p-6 space-y-2 inline-block align-middle w-full text-center py-9 ">
					<h3 className="text-2xl    font-semibold group-hover:underline group-focus:underline">{content?.ElectricalMaterial?.[0]}</h3>
				</div>
				</Link>
			</motion.a>
			<motion.a
				variants={item}
			
			rel="noopener noreferrer" href="#" className="w-[256px] mx-auto group hover:no-underline focus:no-underline bg-gray-900">
				<Link to="/elektrik-malzemeleri/sac-beton-kosk">
				<img role="presentation" className="object-cover w-full rounded h-44 bg-gray-500 " src="/images/elektrik/sac/1.jpg" />
				<div className="p-6 space-y-2 text-center" >
					<h3 className="text-2xl font-semibold group-hover:underline group-focus:underline">{content?.ElectricalMaterial?.[1]}</h3>
				</div>
				</Link>
			</motion.a>
			
			<motion.a
				variants={item}
			
			rel="noopener noreferrer" href="#" className="w-[256px] mx-auto group hover:no-underline focus:no-underline bg-gray-900">
					<Link to="/elektrik-malzemeleri/transformatorler">
			<img role="presentation" className="object-cover w-full rounded h-44 bg-gray-500 " src="/images/elektrik/trans/x.jpg" />
			<div className="p-6 space-y-2 inline-block align-middle w-full text-center py-9    my-2">

<h3 className="text-2xl font-semibold group-hover:underline group-focus:underline">{content?.ElectricalMaterial?.[2]}</h3>
</div>
				</Link>
			</motion.a>
			<motion.a
				variants={item}
				rel="noopener noreferrer" href="#" className="w-[256px] mx-auto group hover:no-underline focus:no-underline bg-gray-900">
					<Link to="/elektrik-malzemeleri/ag-panolar">
						
			<img role="presentation" className="object-cover w-full rounded h-44 bg-gray-500 " src="/images/elektrik/ag/x.jpg" />
			<div className="p-6 space-y-2 inline-block align-middle text-center w-full py-9 ">

					<h3 className="text-2xl font-semibold group-hover:underline group-focus:underline">{content?.ElectricalMaterial?.[3]}</h3>
				</div>
				</Link>
			</motion.a>

			<motion.a
				variants={item}
				rel="noopener noreferrer" href="#" className="w-[256px] mx-auto group hover:no-underline focus:no-underline bg-gray-900">
					<Link to="/elektrik-malzemeleri/rtu-panolar">

			<img role="presentation" className="object-cover w-full rounded h-44 bg-gray-500 " src="/images/elektrik/rtu-pano/0.jpg" />	
			<div className="p-6 space-y-2 inline-block align-middle w-full text-center py-9   my-5">

					<h3 className="text-2xl font-semibold group-hover:underline group-focus:underline">{content?.ElectricalMaterial?.[4]}</h3>
					</div>
					</Link>
			</motion.a>
			<motion.a
				variants={item}
				rel="noopener noreferrer" href="#" className="w-[256px] mx-auto group hover:no-underline focus:no-underline bg-gray-900">
					<Link to="/elektrik-malzemeleri/aku-redresor">
			<img role="presentation" className="object-cover w-full rounded h-44 bg-gray-500 " src="/images/elektrik/aku-redresor/main.jpg" />	
			<div className="p-6 space-y-2 inline-block align-middle w-full text-center py-9 ">

					<h3 className="text-2xl font-semibold group-hover:underline group-focus:underline">{content?.ElectricalMaterial?.[5]}</h3>
					</div>
					</Link>
			</motion.a>
			<motion.a
				variants={item}
				rel="noopener noreferrer" href="#" className="w-[256px] mx-auto group hover:no-underline focus:no-underline bg-gray-900">
					<Link to="/elektrik-malzemeleri/ag-parafudr">
			<img role="presentation" className="object-cover w-full rounded h-44 bg-gray-500 " src="/images/elektrik/ag-parafudr/main.jpg" />	
			<div className="p-6 space-y-2 inline-block align-middle w-full text-center py-9 ">

					<h3 className="text-2xl font-semibold group-hover:underline group-focus:underline">{content?.ElectricalMaterial?.[6]}</h3>
					</div>
					</Link>
			</motion.a>
			<motion.a
				variants={item}
				rel="noopener noreferrer" href="#" className="w-[256px] mx-auto group hover:no-underline focus:no-underline bg-gray-900">
								<Link to="/elektrik-malzemeleri/enerji-izleme">

			<img role="presentation" className="object-cover w-full rounded h-44 bg-gray-500 " src="/images/elektrik/enerji-izleme/x.jpg" />	
			<div className="p-6 space-y-2 inline-block align-middle w-full text-center ">

					<h3 className="text-2xl font-semibold group-hover:underline group-focus:underline">{content?.ElectricalMaterial?.[7]}</h3>
				</div>
				</Link>
			</motion.a>
			
		</motion.div>
		<div className="flex justify-center">
		</div>
	</div>
</section></div>
  )
}

export default ElektrikMalzemeleri