import React from 'react'
import {MdOutlineElectricCar, MdOutlineBatteryChargingFull ,MdOutlineElectricMeter , MdPlayArrow} from "react-icons/md"
import "./Business.css"
import { Link } from "react-router-dom";

const Business = ({setOpenFaliyetAlanları,content}) => {
  return (
    <div><section className="py-10 bg-gray-800 text-gray-100 lg:block hidden">
	<div className="container px-4 mx-auto">
		<div className="max-w-2xl mx-auto mb-8 text-center">
			<h2 className="text-2xl font-bold lg:text-5xl">Faaliyet Alanları</h2>
		</div>
		<div className="flex items-stretch -mx-4">
		
			<div className="flex w-full  sm:px-4 md:w-1/2 lg:w-1/3 lg:mb-0  ">
			<Link to="/e-mobility" className='w-full mx-auto after:content[""]   after:h-3    after:bg-white after:scale-110 relative after:hover:w-full after:w-0 after:absolute after:transition-all after:duration-1000 after:mx-auto  '
							onClick={()=>{
								setOpenFaliyetAlanları(false)
							}}
						>
			<div className="transition-all duration-1000 hover:scale-110  flex flex-col p-6 space-y-6 rounded shadow sm:p-8 bg-yellow-400/70 hover:bg-yellow-400 text-white hover:text-gray-900 w-full border border-yellow-400" >
                <div className=' mx-auto'>
                        <MdOutlineElectricCar size={40}/>
                    </div>
                    <div className="space-y-2 flex">
					
                    <h4 className="text-xl font-bold mx-auto  flex  cursor-pointer" >{content?.FieldsOfOperation?.[0]} <MdPlayArrow className='mt-1 ml-4  '/></h4> 
					</div>
					<div>
                    </div>
				</div>
				</Link>
			</div>
			<div className="flex w-full  sm:px-4 md:w-1/2 lg:w-1/3 lg:mb-0  ">
			<Link to="/elektrik-malzemeleri" className='w-full mx-auto after:content[""]   after:h-3    after:bg-white after:scale-110 relative after:hover:w-full after:w-0 after:absolute after:transition-all after:duration-1000 after:mx-auto  '
							onClick={()=>{
								setOpenFaliyetAlanları(false)
							}}
						>
			<div className="transition-all duration-1000 hover:scale-110  flex flex-col p-6 space-y-6 rounded shadow sm:p-8 bg-yellow-400/70 hover:bg-yellow-400 text-white hover:text-gray-900 w-full border border-yellow-400" >
                <div className=' mx-auto'>
                        <MdOutlineBatteryChargingFull size={40}/>
                    </div>
                    <div className="space-y-2 flex">
					
                    <h4 className="text-xl font-bold mx-auto  flex  cursor-pointer" >{content?.FieldsOfOperation?.[1]} <MdPlayArrow className='mt-1 ml-4  '/></h4> 
					</div>
					<div>
                    </div>
				</div>
				</Link>
			</div>
			<div className="flex w-full  sm:px-4 md:w-1/2 lg:w-1/3 lg:mb-0  ">
			<Link to="/enerji-depolama" className='w-full mx-auto after:content[""]   after:h-3    after:bg-white after:scale-110 relative after:hover:w-full after:w-0 after:absolute after:transition-all after:duration-1000 after:mx-auto  '
							onClick={()=>{
								setOpenFaliyetAlanları(false)
							}}
						>
			<div className="transition-all duration-1000 hover:scale-110  flex flex-col p-6 space-y-6 rounded shadow sm:p-8 bg-yellow-400/70 hover:bg-yellow-400 text-white hover:text-gray-900 w-full border border-yellow-400" >
                <div className=' mx-auto'>
                        <MdOutlineElectricMeter size={40}/>
                    </div>
                    <div className="space-y-2 flex">
					
                    <h4 className="text-xl font-bold mx-auto  flex  cursor-pointer" >{content?.FieldsOfOperation?.[2]} <MdPlayArrow className='mt-1 ml-4  '/></h4> 
					</div>
					<div>
                    </div>
				</div>
				</Link>
			</div>
		
          
		
		</div>
	</div>
</section></div>
  )
}

export default Business