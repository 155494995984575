import React, { useEffect } from 'react'

const Hakkimizda = ({content}) => {

  return (
    <div className='mt-20 w-screen'>
        
        <section className="p-4 lg:p-8  text-gray-100">
        <div>
				<h2 className="text-3xl font-bold tracki text-center sm:text-5xl text-gray-900 my-24">{content.WhoWeAreTitle}</h2>
			</div>
	<div id="biz-kimiz"  className="container mx-auto space-y-12">
		<div className="flex flex-col overflow-hidden rounded-md shadow-sm lg:flex-row">
			<img src="/genel/G2.jpeg" alt="" className="h-80 bg-gray-500 aspect-video" />
			<div className="flex flex-col justify-center flex-1 p-6 bg-gray-900">
				<h3 className="text-3xl font-bold">{content?.AboutPageTitles?.[0]} </h3>
				<p className="my-6 text-gray-400">{content?.AboutPageDescriptions?.[0]} </p>
			</div>
		</div>
		<div id='vizyon' className="flex flex-col overflow-hidden rounded-md shadow-sm lg:flex-row-reverse">
			<img src="/genel/G5.jpeg" alt="" className="h-80 bg-gray-500 aspect-video" />
			<div className="flex flex-col justify-center flex-1 p-6 bg-gray-900">
				<h3 className="text-3xl font-bold">{content?.AboutPageTitles?.[1]}</h3>
				<p className="my-6 text-gray-400">{content?.AboutPageDescriptions?.[1]} </p>
			</div>
		</div>
		<div id="misyon" className="flex flex-col overflow-hidden rounded-md shadow-sm lg:flex-row">
			<img src="/genel/G1.jpeg" alt="" className="h-80 bg-gray-500 aspect-video" />
			<div className="flex flex-col justify-center flex-1 p-6 bg-gray-900">
				<h3 className="text-3xl font-bold">{content?.AboutPageTitles?.[2]}</h3>
				<p className="my-6 text-gray-400">{content?.AboutPageDescriptions?.[2]} </p>
			</div>
		</div>
	</div>
</section>
    </div>
  )
}

export default Hakkimizda