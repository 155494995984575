import React, { useRef, useState, useEffect } from 'react';
import DetailsThumb from '../DetailsThumb';
import "../og.css"
import {TiTick} from "react-icons/ti"
import  {motion} from "framer-motion"
import { Link } from "react-router-dom";
import { CheckIcon } from '@heroicons/react/20/solid'





export default function AGPanolar({setIsOpenContantPage,content}) {

	console.log(setIsOpenContantPage)
	var [products, setProducts] = useState([
	  {
		"_id": "1",
		"src": [
		  "/images/elektrik/ag/news/0.jpg",
		  "/images/elektrik/ag/news/1.jpg",
		  "/images/elektrik/ag/news/2.jpg",
		  "/images/elektrik/ag/news/3.jpg",
		  "/images/elektrik/ag/news/4.jpg",
		],
		"description": "Orta Gerilim Hücreleri"
	  }
	]);
	var [index, setIndex] = useState(0);
  
	const myRef = useRef(null);
  
	const handleTab = (index) => {
	  setIndex(index);
	};
	

  
	useEffect(() => {
	  if (myRef.current) {
		const images = myRef.current.children;
		for (let i = 0; i < images.length; i++) {
		  images[i].className = images[i].className.replace("active", "");
		}
		images[index].className = "active";
	  }
	}, [index]);
  
	useEffect(() => {
	  if (myRef.current) {
		myRef.current.children[index].className = "active";
	  }
	}, []);
  
	  return(
			<motion.div 
			initial={{ translateX: "-100%"}}
			animate={{ translateX: "0"}}
			transition={{duration: 0.5}}
			className='w-screen border'>
			   <div className='mt-24 w-full h-16 bg-yellow-400 sticky top-[88px] z-50'>
			  <nav aria-label="breadcrumb" className="w-full p-4 ">
		  <ol className="flex h-8 space-x-2">
			  <li className="flex items-center">
			<Link to="/">
				  <a rel="noopener noreferrer" href="#" title="Back to homepage" className="hover:underline">
					  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5 pr-1 dark:text-gray-400">
						  <path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"></path>
					  </svg>
				  </a>
			</Link>
			  </li>
		  <Link to="/elektrik-malzemeleri" className='flex items-center space-x-2'> 
				  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" aria-hidden="true" fill="currentColor" className="w-2 h-2 mt-1 transform rotate-90 fill-current dark:text-gray-600">
					  <path d="M32 30.031h-32l16-28.061z"></path>
				  </svg>
				  <a rel="noopener noreferrer" href="#" className="flex items-center px-1 capitalize hover:underline font-medium">Elektrik Malzemeleri</a>
		  </Link>
			  <li className="flex items-center space-x-2">
				  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" aria-hidden="true" fill="currentColor" className="w-2 h-2 mt-1 transform rotate-90 fill-current dark:text-gray-600">
					  <path d="M32 30.031h-32l16-28.061z"></path>
				  </svg>
				  <a rel="noopener noreferrer" href="#" className="flex items-center px-1 capitalize hover:underline font-medium">{content?.AGPanel?.[0]}</a>
			  </li>
	  
		  </ol>
	  </nav>
			  </div>
			  
			  <section className=" text-gray-900 w-screen ">
		  <div className="container max-w-xl p-6  mx-auto  lg:px-32 lg:max-w-7xl ">
			  <div className=''> 
	  		<div>
			<h2 className="text-3xl font-bold tracki text-center sm:text-5xl text-yellow-400 drop-shadow-[0_1.2px_1.2px_rgba(0,0,0,0.8)] my-12">{content?.AGPanel?.[0]}</h2>

		</div>
			  </div>
			  <div className="app  mb-24  bg-gray-200 mx-auto">
	  
	  {
		products.map(item =>(
		  <div className="details" >
			<div className="big-img shadow-2xl shadow-gray-700 shadow max-h-[400px] my-auto  ">
			  <img className='  bg-white' src={item.src[index]} alt=""/>
			</div>
			<div className="box font-medium " >
		
			  <div className="row">
				<h2 className='font-bold text-gray-700 text-xl'>{content?.AGPanel?.[1]}</h2>
			  </div>
			
	  
		 <div className=' my-auto inline-block align-middle mt-12'>
			  <DetailsThumb className="mx-auto  " images={item.src} tab={handleTab} myRef={myRef} />
			  </div>

        
			</div>
		  </div>
		))
	  }
	  </div>
	  
	  
			  <div className='   '>
				  <div className="grid lg:gap-32 lg:grid-cols-2 lg:items-center">
					  <div className="lg:col-start-2">
						  <h3 className="text-2xl font-bold tracki sm:text-3xl text-yellow-400 drop-shadow-[0_1.2px_1.2px_rgba(0,0,0,0.8)]">{content?.AGPanel?.[2]}</h3>
						  <div className="mt-12 space-y-6">
	  
						  
							  <div className="flex">
								  <div className="flex-shrink-0">
									  <div className="flex items-center justify-center w-6 h-6 rounded-md bg-yellow-400 text-gray-900">
										  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="w-4 h-4">
											  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
										  </svg>
									  </div>
								  </div>
								  <div className="ml-4">
									  <h4 className="text-lg font-medium leadi text-gray-950">{content?.AGPanelFeatures?.[0]}</h4>
								  </div>
							  </div>
							  <div className="flex">
								  <div className="flex-shrink-0">
									  <div className="flex items-center justify-center w-6 h-6 rounded-md bg-yellow-400 text-gray-900">
										  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="w-4 h-4">
											  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
										  </svg>
									  </div>
								  </div>
								  <div className="ml-4">
									  <h4 className="text-lg font-medium leadi text-gray-950">{content?.AGPanelFeatures?.[1]}</h4>
								  </div>
							  </div>
							  <div className="flex">
								  <div className="flex-shrink-0">
									  <div className="flex items-center justify-center w-6 h-6 rounded-md bg-yellow-400 text-gray-900">
										  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="w-4 h-4">
											  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
										  </svg>
									  </div>
								  </div>
								  <div className="ml-4">
									  <h4 className="text-lg font-medium leadi text-gray-950">{content?.AGPanelFeatures?.[2]}</h4>
								  </div>
							  </div>
							  <div className="flex">
								  <div className="flex-shrink-0">
									  <div className="flex items-center justify-center w-6 h-6 rounded-md bg-yellow-400 text-gray-900">
										  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="w-4 h-4">
											  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
										  </svg>
									  </div>
								  </div>
								  <div className="ml-4">
									  <h4 className="text-lg font-medium leadi text-gray-950">{content?.AGPanelFeatures?.[3]}</h4>
								  </div>
							  </div>
							  <div className="flex">
								  <div className="flex-shrink-0">
									  <div className="flex items-center justify-center w-6 h-6 rounded-md bg-yellow-400 text-gray-900">
										  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="w-4 h-4">
											  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
										  </svg>
									  </div>
								  </div>
								  <div className="ml-4">
									  <h4 className="text-lg font-medium leadi text-gray-950">{content?.AGPanelFeatures?.[4]}</h4>
								  </div>
							  </div>
							  <div className="flex">
								  <div className="flex-shrink-0">
									  <div className="flex items-center justify-center w-6 h-6 rounded-md bg-yellow-400 text-gray-900">
										  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="w-4 h-4">
											  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
										  </svg>
									  </div>
								  </div>
								  <div className="ml-4">
									  <h4 className="text-lg font-medium leadi text-gray-950">{content?.AGPanelFeatures?.[5]}</h4>
								  </div>
							  </div>
							  <div className="flex">
								  <div className="flex-shrink-0">
									  <div className="flex items-center justify-center w-6 h-6 rounded-md bg-yellow-400 text-gray-900">
										  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="w-4 h-4">
											  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
										  </svg>
									  </div>
								  </div>
								  <div className="ml-4">
									  <h4 className="text-lg font-medium leadi text-gray-950">{content?.AGPanelFeatures?.[6]}</h4>
								  </div>
							  </div>
							  <div className="flex">
								  <div className="flex-shrink-0">
									  <div className="flex items-center justify-center w-6 h-6 rounded-md bg-yellow-400 text-gray-900">
										  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="w-4 h-4">
											  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
										  </svg>
									  </div>
								  </div>
								  <div className="ml-4">
									  <h4 className="text-lg font-medium leadi text-gray-950">{content?.AGPanelFeatures?.[7]}</h4>
								  </div>
							  </div>
						  
						  
						  </div>
					  </div>
					  <div className="mt-10 lg:mt-0 lg:col-start-1 lg:row-start-1">
						  <img src="/images/elektrik/ag/1.jpg" alt="" className="mx-auto rounded-lg shadow-lg bg-gray-500" />
					  </div>
				  </div>
			  </div>
		  </div>
	  </section>
	  <div className='py-12'>
	  <center>
				  <button 
						  onClick={()=>{
						  setIsOpenContantPage(true)
						}}type="button" className="px-8 py-3 bg-gray-800 text-yellow-400 font-semibold border rounded border-gray-700  hover:bg-yellow-400 hover:text-gray-800  transition-all duration-200">{content?.ElectricalMaterial?.[8]}</button>
				</center>
			</div>
			</motion.div>
		);
	  };



