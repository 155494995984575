

import React from "react";
import {FaInstagram} from "react-icons/fa"
import {BsLinkedin} from "react-icons/bs"
import {FaYoutube} from "react-icons/fa"
import { Link } from "react-router-dom";

const Footer = ({setIsOpenContantPage,content}) => {
  return (
    <div className="bg-gray-800 text-white">
      <div className="container mx-auto pt-16 pb-6">
        <div className="flex md:justify-between justify-center text-center flex-wrap md:gap-y-0 gap-y-6 ">
          <div className="md:flex-1">
            <h2 className="text-[30px] text-yellow-400">{content?.Footer?.[0]}</h2>
            <div className="flex flex-col gap-y-2 mt-3">
              <div>
                <i className="fa fa-map-marker"></i>
                <span className="inline-block ml-2"> <span>Ugur Mumcu Mah. Rajiv Gandi Cad. 1649 Sk. No: 2G Yenimahalle/Ankara-Türkiye</span></span>
              </div>
              <div>
                <i className="fa fa-phone"></i>
                <span className="inline-block ml-2"> +90 533 764 40 22</span>
              </div>
              <div>
                <i className="fa fa-envelope"></i>
                <span className="inline-block ml-2">info@deenerji.com.tr</span>
              </div>
            </div>
          </div>
          <div className="md:flex-1">
            <Link to="/">
            <h2 className="text-[30px] text-bolder text-yellow-400 cursor-pointer"
            onClick={()=>{
              setTimeout(()=>{
              document.body.scrollTop = document.documentElement.scrollTop = 0;
              })

            }}
            >DE Enerji</h2>
            </Link>
            <p className="mt-3">
            {content?.Footer?.[1]}
            </p>
            <div className="flex items-center justify-center mt-5 gap-x-8">
              <a
                href="https://www.instagram.com/de_enerji"
                className="w-8 h-8 grid place-content-center   rounded-full "
              >
                <FaInstagram size={30}/>
              </a>
              <a
                href=""
                className="w-8 h-8 grid place-content-center  rounded-full"
              >
                <BsLinkedin size={30}/>
              </a>
              <a
                href=""
                className="w-8 h-8 grid place-content-center  rounded-full"
              >
              <FaYoutube size={30}/>
              </a>
              
            
            </div>
          </div>
          <div className="md:flex-1">
            <h2 className="text-[30px] text-bold text-yellow-400">{content?.Footer?.[2]}</h2>
            <div className="flex flex-col gap-y-2 mt-3">
              <div>
                <Link to="/hakkimizda" onClick={()=>{
                  setTimeout(()=>{
                    document.body.scrollTop = document.documentElement.scrollTop = 0;
                  },1)
                }}>
                <span className="inline-block ml-2">{content?.Footer?.[3]}</span>
                </Link>
              </div>
          
              <div>
                <Link to="/e-mobility" onClick={()=>{
                  setTimeout(()=>{
                    document.body.scrollTop = document.documentElement.scrollTop = 0;
                  },1)
                }}>
                <span className="inline-block ml-2">{content?.Footer?.[4]}</span>
                </Link>
              </div>
              <div>
              <Link to="/elektrik-malzemeleri" onClick={()=>{
                  setTimeout(()=>{
                    document.body.scrollTop = document.documentElement.scrollTop = 0;
                  },1)
                }}>
                <span className="inline-block ml-2">{content?.Footer?.[5]}</span>
                </Link>
              </div>
              <div>
              <Link to="/enerji-depolama" onClick={()=>{
                  setTimeout(()=>{
                    document.body.scrollTop = document.documentElement.scrollTop = 0;
                  },1)
                }}>
                <span className="inline-block ml-2">{content?.Footer?.[6]}</span>
                </Link>
              </div>
              <div
              onClick={()=>{
                setIsOpenContantPage(true)
              }}
              >
                
                <span className="inline-block ml-2 cursor-pointer">{content?.Footer?.[7]}</span>
              </div>
            </div>
          </div>
        </div>
        <p className="text-center mt-10">
          © 2023 All Rights Reserved 
        </p>
      </div>
    </div>
  );
};

export default Footer;
