import React, { useRef, useState, useEffect } from 'react';
import {TiTick} from "react-icons/ti"
import  {motion} from "framer-motion"
import { Link } from "react-router-dom";
import { CheckIcon } from '@heroicons/react/20/solid'

export default function Transformatorler({setIsOpenContantPage,content}) {
  
const includedFeatures = [
  'Private forum access',
  'Member resources',
  'Entry to annual conference',
  'Official member t-shirt',
]
  var [products, setProducts] = useState([
    {
      "_id": "1",
      "title": "OG Hücreleri",
      "src": [
        "/images/elektrik/og/x.png",
        "/images/elektrik/og/x.jpg",
        "/images/elektrik/og/y.jpeg"
      ],
      "description": "Orta Gerilim Hücreleri"
    }
  ]);
  var [index, setIndex] = useState(0);

  const myRef = useRef(null);

  const handleTab = (index) => {
    setIndex(index);
  };

  useEffect(() => {
    if (myRef.current) {
      const images = myRef.current.children;
      for (let i = 0; i < images.length; i++) {
        images[i].className = images[i].className.replace("active", "");
      }
      images[index].className = "active";
    }
  }, [index]);

  useEffect(() => {
    if (myRef.current) {
      myRef.current.children[index].className = "active";
    }
  }, []);

    return(
      <motion.div 
      initial={{ translateX: "-100%"}}
      animate={{ translateX: "0"}}
      transition={{duration: 0.5}}
      className='w-screen border'>
               <div className='mt-24 w-full h-16 bg-yellow-400 sticky top-[88px] z-50'>
        <nav aria-label="breadcrumb" className="w-full p-4 ">
	<ol className="flex h-8 space-x-2">
		<li className="flex items-center">
      <Link to="/">
			<a rel="noopener noreferrer" href="#" title="Back to homepage" className="hover:underline">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5 pr-1 dark:text-gray-400">
					<path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"></path>
				</svg>
			</a>
      </Link>
		</li>
    <Link to="/elektrik-malzemeleri" className='flex items-center space-x-2'> 
			<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" aria-hidden="true" fill="currentColor" className="w-2 h-2 mt-1 transform rotate-90 fill-current dark:text-gray-600">
				<path d="M32 30.031h-32l16-28.061z"></path>
			</svg>
			<a rel="noopener noreferrer" href="#" className="flex items-center px-1 capitalize hover:underline font-medium">Elektrik Malzemeleri</a>
    </Link>
		<li className="flex items-center space-x-2">
			<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" aria-hidden="true" fill="currentColor" className="w-2 h-2 mt-1 transform rotate-90 fill-current dark:text-gray-600">
				<path d="M32 30.031h-32l16-28.061z"></path>
			</svg>
			<a rel="noopener noreferrer" href="#" className="flex items-center px-1 capitalize hover:underline font-medium">{content?.Transformers?.[0]}</a>
		</li>

	</ol>
</nav>
        </div>
        <div className="bg-white pt-24  sm:pt-32 sm:pb-12 z-1">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl sm:text-center">
          <h2 className="text-3xl font-bold tracking-tight text-yellow-400 drop-shadow-[0_1.2px_1.2px_rgba(0,0,0,0.8)] sm:text-4xl">{content?.Transformers?.[0]}</h2>
          <p className="mt-6 text-lg leading-8 text-gray-600">
         
          </p>
        </div>
        
        
        <div className="mx-auto mt-6 max-w-2xl rounded-3xl ring-1 shadow-lg ring-yellow-400  sm:mt-12 lg:mx-0 lg:flex lg:max-w-none">
          <div className="p-8 sm:p-10 lg:flex-auto">
            <h3 className="text-2xl font-bold tracking-tight text-yellow-400 drop-shadow-[0_1.2px_1.2px_rgba(0,0,0,0.8)]">{content?.Transformers?.[1]}</h3>
       
            <div className="mt-10 flex items-center gap-x-4">
              <h4 className="flex-none text-sm font-semibold leading-6 text-gray-850">{content?.Transformers?.[2]}</h4>
              <div className="h-px flex-auto bg-gray-100" />
            </div>
            <div className=''>

            </div>
            <ul
              role="list"
              className="mt-8 grid grid-cols-1 gap-4 text-sm leading-6 text-gray-600 sm:grid-cols-2 sm:gap-6"
            >
            
                <li  className="flex gap-x-3">
                  <CheckIcon className="h-6 w-5 flex-none text-yellow-400" aria-hidden="true" />
                  <p className='font-bold'>
                  {content?.Transformers?.[3]}
                  </p>
                  
                </li>    
                <li  className="flex gap-x-3">
                  <CheckIcon className="h-6 w-5 flex-none text-yellow-400" aria-hidden="true" />
                  <p className='font-bold'>
                  {content?.Transformers?.[4]}
                  </p>
                </li>
            </ul>
          </div>
          <div className="-mt-2 p-2 lg:mt-0 lg:w-full lg:max-w-md lg:flex-shrink-0">
            <div className='relative flex xh-[150px] w-[200px]  md:h-[300px] md:w-[400px]   mx-auto '>
                <img  src="/images/elektrik/trans/0.jpeg" className='mx-auto' />
              </div>
          </div>
        </div>
        <div className="mx-auto mt-12 max-w-2xl rounded-3xl ring-1 shadow-lg ring-yellow-400   sm:mt-12 lg:mx-0 lg:flex lg:max-w-none">
          <div className="p-8 sm:p-10 lg:flex-auto">
            <h3 className="text-2xl font-bold tracking-tight text-yellow-400 drop-shadow-[0_1.2px_1.2px_rgba(0,0,0,0.8)]">{content?.Transformers?.[5]}</h3>
       
            <div className="mt-10 flex items-center gap-x-4">
              <h4 className="flex-none text-sm font-semibold leading-6 text-gray-850">{content?.Transformers?.[2]}</h4>
              <div className="h-px flex-auto bg-gray-100" />
            </div>
            <div className=''>

            </div>
            <ul
              role="list"
              className="mt-8 grid grid-cols-1 gap-4 text-sm leading-6 text-gray-600 sm:grid-cols-2 sm:gap-6"
            >
            
                <li  className="flex gap-x-3">
                  <CheckIcon className="h-6 w-5 flex-none text-yellow-400" aria-hidden="true" />
                  <p className='font-bold'>
                  {content?.Transformers?.[3]}
                  </p>
                  
                </li>    
                <li  className="flex gap-x-3">
                  <CheckIcon className="h-6 w-5 flex-none text-yellow-400" aria-hidden="true" />
                  <p className='font-bold'>
                  {content?.Transformers?.[6]}                  </p>
                </li>
               
            
            </ul>
          </div>
          <div className="-mt-2 p-2 lg:mt-0 lg:w-full lg:max-w-md lg:flex-shrink-0">
            <div className='relative flex xh-[150px] w-[200px]  md:h-[300px] md:w-[400px]   mx-auto '>
                <img  src="/images/elektrik/trans/11.jpeg" className='mx-auto' />
              </div>
          </div>
          
        </div>
        <div className='py-12'>
        <center>
            <button 
                	onClick={()=>{
                    setIsOpenContantPage(true)
                  }}type="button" className="px-8 py-3 bg-gray-800 text-yellow-400 font-semibold border rounded border-gray-700  hover:bg-yellow-400 hover:text-gray-800  transition-all duration-200">{content?.ElectricalMaterial?.[8]}</button>
          </center>
      </div>
      </div>
    
    </div>
      
   
      </motion.div>
  );
};

