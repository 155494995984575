
import React from 'react'
import  {motion} from "framer-motion"
import { Link } from "react-router-dom";

const AkuRedresor = ({setIsOpenContantPage,content}) => {
  return (
	<motion.div 
      initial={{ translateX: "-100%"}}
      animate={{ translateX: "0"}}
      transition={{duration: 0.5}}
      className='w-screen 	  '>
               <div className='mt-24 w-full h-16 bg-yellow-400 sticky top-[88px] z-50 mb-32'>
        <nav aria-label="breadcrumb" className="w-full p-4 ">
	<ol className="flex h-8 space-x-2">
		<li className="flex items-center">
      <Link to="/">
			<a rel="noopener noreferrer" href="#" title="Back to homepage" className="hover:underline">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5 pr-1 dark:text-gray-400">
					<path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"></path>
				</svg>
			</a>
      </Link>
		</li>
    <Link to="/elektrik-malzemeleri" className='flex items-center space-x-2'> 
			<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" aria-hidden="true" fill="currentColor" className="w-2 h-2 mt-1 transform rotate-90 fill-current dark:text-gray-600">
				<path d="M32 30.031h-32l16-28.061z"></path>
			</svg>
			<a rel="noopener noreferrer" href="#" className="flex items-center px-1 capitalize hover:underline font-medium">Elektrik Malzemeleri</a>
    </Link>
		<li className="flex items-center space-x-2">
			<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" aria-hidden="true" fill="currentColor" className="w-2 h-2 mt-1 transform rotate-90 fill-current dark:text-gray-600">
				<path d="M32 30.031h-32l16-28.061z"></path>
			</svg>
			<a rel="noopener noreferrer" href="#" className="flex items-center px-1 capitalize hover:underline font-medium">{content?.AkuRedresor?.[0]}</a>
		</li>

	</ol>
</nav>
</div>
    <section className=" text-gray-900 w-screen ">
	<div className="container max-w-xl p-6 pb-12 mx-auto space-y-24 lg:px-8 lg:max-w-7xl">
		<div>
			<h2 className="text-3xl font-bold tracki text-center sm:text-5xl text-yellow-400 drop-shadow-[0_1.2px_1.2px_rgba(0,0,0,0.8)]">{content?.AkuRedresor?.[0]}</h2>
			<p className="max-w-3xl mx-auto mt-4 text-xl text-center text-gray-700">{content?.AkuRedresor?.[1]}</p>

		</div>
		<div className="grid lg:gap-8 lg:grid-cols-2 lg:items-center">
			<div>
				<h3 className="text-2xl font-bold tracki sm:text-3xl text-yellow-400 drop-shadow-[0_1.2px_1.2px_rgba(0,0,0,0.8)]">{content?.AkuRedresor?.[2]}</h3>
                			<p className="max-w-3xl float-left mt-4 text-xl text-center text-gray-700">{content?.AkuRedresor?.[3]}</p>

				<div className="mt-24 space-y-4">
					<div className="flex">
						<div className="flex-shrink-0">
							<div className="flex items-center justify-center w-6 h-6 rounded-md bg-yellow-400 text-gray-900">
								<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="w-4 h-4">
									<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
								</svg>
							</div>
						</div>
						<div className="ml-4">
							<h4 className="text-lg font-medium leadi text-gray-900">{content?.AkuRedresor?.[4]}</h4>
						</div>
					</div>
					<div className="flex">
						<div className="flex-shrink-0">
							<div className="flex items-center justify-center w-6 h-6 rounded-md bg-yellow-400 text-gray-900">
								<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="w-4 h-4">
									<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
								</svg>
							</div>
						</div>
						<div className="ml-4">
							<h4 className="text-lg font-medium leadi text-gray-900">{content?.AkuRedresor?.[5]}</h4>
						</div>
					</div>
					<div className="flex">
						<div className="flex-shrink-0">
							<div className="flex items-center justify-center w-6 h-6 rounded-md bg-yellow-400 text-gray-900">
								<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="w-4 h-4">
									<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
								</svg>
							</div>
						</div>
						<div className="ml-4">
							<h4 className="text-lg font-medium leadi text-gray-900">{content?.AkuRedresor?.[6]}</h4>
						</div>
					</div>
					<div className="flex">
						<div className="flex-shrink-0">
							<div className="flex items-center justify-center w-6 h-6 rounded-md bg-yellow-400 text-gray-900">
								<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="w-4 h-4">
									<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
								</svg>
							</div>
						</div>
						<div className="ml-4">
							<h4 className="text-lg font-medium leadi text-gray-900">{content?.AkuRedresor?.[7]}</h4>
						</div>
					</div>
					<div className="flex">
						<div className="flex-shrink-0">
							<div className="flex items-center justify-center w-6 h-6 rounded-md bg-yellow-400 text-gray-900">
								<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="w-4 h-4">
									<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
								</svg>
							</div>
						</div>
						<div className="ml-4">
							<h4 className="text-lg font-medium leadi text-gray-900">{content?.AkuRedresor?.[8]}</h4>
						</div>
					</div>
					<div className="flex">
						<div className="flex-shrink-0">
							<div className="flex items-center justify-center w-6 h-6 rounded-md bg-yellow-400 text-gray-900">
								<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="w-4 h-4">
									<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
								</svg>
							</div>
						</div>
						<div className="ml-4">
							<h4 className="text-lg font-medium leadi text-gray-900">{content?.AkuRedresor?.[9]}</h4>
						</div>
					</div>
					<div className="flex">
						<div className="flex-shrink-0">
							<div className="flex items-center justify-center w-6 h-6 rounded-md bg-yellow-400 text-gray-900">
								<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="w-4 h-4">
									<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
								</svg>
							</div>
						</div>
						<div className="ml-4">
							<h4 className="text-lg font-medium leadi text-gray-900">{content?.AkuRedresor?.[10]}</h4>
						</div>
					</div>
					<div className="flex">
						<div className="flex-shrink-0">
							<div className="flex items-center justify-center w-6 h-6 rounded-md bg-yellow-400 text-gray-900">
								<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="w-4 h-4">
									<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
								</svg>
							</div>
						</div>
						<div className="ml-4">
							<h4 className="text-lg font-medium leadi text-gray-900">{content?.AkuRedresor?.[11]}</h4>
						</div>
					</div>
					<div className="flex">
						<div className="flex-shrink-0">
							<div className="flex items-center justify-center w-6 h-6 rounded-md bg-yellow-400 text-gray-900">
								<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="w-4 h-4">
									<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
								</svg>
							</div>
						</div>
						<div className="ml-4">
							<h4 className="text-lg font-medium leadi text-gray-900">{content?.AkuRedresor?.[12]}</h4>
						</div>
					</div>
					
	
				</div>

			</div>
			<div  className='h-full my-auto'>
            <div aria-hidden="true" className="mt-10 lg:mt-0">
				<img src="/images/elektrik/aku-redresor/0.jpg" alt="" className="mx-auto rounded-lg shadow-lg dark:bg-gray-500" />
			</div>
			<div className='max-w-3xl mx-auto mt-4 text-xl text-center text-gray-900 font-semibold tracki'>ASC ECOMAX-T</div>
			</div>
		</div>
		<div className='   '>
			<div className="grid lg:gap-8 lg:grid-cols-2 lg:items-center">
				<div className="lg:col-start-2">
					<h3 className="text-2xl font-bold tracki sm:text-3xl text-yellow-400 drop-shadow-[0_1.2px_1.2px_rgba(0,0,0,0.8)]">{content?.AkuRedresor?.[13]}</h3>
                	<p className="max-w-3xl float-left mt-4 text-xl text-center text-gray-700 ">{content?.AkuRedresor?.[14]}</p>
                    
					<div className="mt-24 space-y-6">
						<div className="flex">
							<div className="flex-shrink-0">
								<div className="flex items-center justify-center w-6 h-6 rounded-md bg-yellow-400 text-gray-900">
									<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="w-4 h-4">
										<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
									</svg>
								</div>
							</div>
							<div className="ml-4">
								<h4 className="text-lg font-medium leadi text-gray-950">{content?.AkuRedresor?.[16]}</h4>
							</div>
						</div>
						<div className="flex">
							<div className="flex-shrink-0">
								<div className="flex items-center justify-center w-6 h-6 rounded-md bg-yellow-400 text-gray-900">
									<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="w-4 h-4">
										<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
									</svg>
								</div>
							</div>
							<div className="ml-4">
								<h4 className="text-lg font-medium leadi text-gray-950">{content?.AkuRedresor?.[17]}</h4>
							</div>
						</div>
						<div className="flex">
							<div className="flex-shrink-0">
								<div className="flex items-center justify-center w-6 h-6 rounded-md bg-yellow-400 text-gray-900">
									<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="w-4 h-4">
										<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
									</svg>
								</div>
							</div>
							<div className="ml-4">
								<h4 className="text-lg font-medium leadi text-gray-950">{content?.AkuRedresor?.[18]}</h4>
							</div>
						</div>
						<div className="flex">
							<div className="flex-shrink-0">
								<div className="flex items-center justify-center w-6 h-6 rounded-md bg-yellow-400 text-gray-900">
									<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="w-4 h-4">
										<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
									</svg>
								</div>
							</div>
							<div className="ml-4">
								<h4 className="text-lg font-medium leadi text-gray-950">{content?.AkuRedresor?.[19]}</h4>
							</div>
						</div>
						<div className="flex">
							<div className="flex-shrink-0">
								<div className="flex items-center justify-center w-6 h-6 rounded-md bg-yellow-400 text-gray-900">
									<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="w-4 h-4">
										<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
									</svg>
								</div>
							</div>
							<div className="ml-4">
								<h4 className="text-lg font-medium leadi text-gray-950">{content?.AkuRedresor?.[20]}</h4>
							</div>
						</div>
						<div className="flex">
							<div className="flex-shrink-0">
								<div className="flex items-center justify-center w-6 h-6 rounded-md bg-yellow-400 text-gray-900">
									<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="w-4 h-4">
										<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
									</svg>
								</div>
							</div>
							<div className="ml-4">
								<h4 className="text-lg font-medium leadi text-gray-950">{content?.AkuRedresor?.[21]}</h4>
							</div>
						</div>
						<div className="flex">
							<div className="flex-shrink-0">
								<div className="flex items-center justify-center w-6 h-6 rounded-md bg-yellow-400 text-gray-900">
									<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="w-4 h-4">
										<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
									</svg>
								</div>
							</div>
							<div className="ml-4">
								<h4 className="text-lg font-medium leadi text-gray-950">{content?.AkuRedresor?.[22]}</h4>
							</div>
						</div>
						<div className="flex">
							<div className="flex-shrink-0">
								<div className="flex items-center justify-center w-6 h-6 rounded-md bg-yellow-400 text-gray-900">
									<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="w-4 h-4">
										<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
									</svg>
								</div>
							</div>
							<div className="ml-4">
								<h4 className="text-lg font-medium leadi text-gray-950">{content?.AkuRedresor?.[23]}</h4>
							</div>
						</div>
						<div className="flex">
							<div className="flex-shrink-0">
								<div className="flex items-center justify-center w-6 h-6 rounded-md bg-yellow-400 text-gray-900">
									<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="w-4 h-4">
										<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
									</svg>
								</div>
							</div>
							<div className="ml-4">
								<h4 className="text-lg font-medium leadi text-gray-950">{content?.AkuRedresor?.[24]}</h4>
							</div>
						</div>
			
						
					</div>
				</div>
				<div className="mt-10 lg:mt-0 lg:col-start-1 lg:row-start-1">
				<img src="/images/elektrik/aku-redresor/1.jpg" alt="" className="mx-auto rounded-lg shadow-lg dark:bg-gray-500" />
				</div>
			</div>
		</div>
	</div>
    
    <div className='container w-screen mx-auto grid lg:gap-8 lg:grid-cols-2 lg:items-center'>
    <div class="bg-white py-24 sm:py-32">
  <div class="mx-auto max-w-7xl px-6 lg:px-8">
    <div class="mx-auto max-w-2xl lg:text-center">
      <p class="mt-2 text-3xl font-bold tracking-tight text-yellow-400 drop-shadow-[0_1.2px_1.2px_rgba(0,0,0,0.8)] sm:text-4xl">ASC 3048 40A MultiMax-T</p>
    </div>
    <div class="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-4xl">
      <dl class="grid max-w-xl grid-cols-1 gap-x-8 gap-y-10 lg:max-w-none lg:grid-cols-2 lg:gap-y-16">
        <div class="relative pl-16">
          <dt class="text-base font-semibold leading-7 text-gray-900">
            <div class="absolute left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg bg-yellow-400">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="w-4 h-4">
    <circle cx="12" cy="12" r="5" fill="currentColor" />
</svg>

            </div>
			{content?.AkuRedresor?.[25]}
          </dt>
        </div>
        <div class="relative pl-16">
          <dt class="text-base font-semibold leading-7 text-gray-900">
            <div class="absolute left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg bg-yellow-400">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="w-4 h-4">
    <circle cx="12" cy="12" r="5" fill="currentColor" />
</svg>

            </div>
            {content?.AkuRedresor?.[26]}        </dt>
        </div>
        <div class="relative pl-16">
          <dt class="text-base font-semibold leading-7 text-gray-900">
            <div class="absolute left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg bg-yellow-400">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="w-4 h-4">
    <circle cx="12" cy="12" r="5" fill="currentColor" />
</svg>

            </div>
			{content?.AkuRedresor?.[27]}       </dt>
        </div>
        <div class="relative pl-16">
          <dt class="text-base font-semibold leading-7 text-gray-900">
            <div class="absolute left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg bg-yellow-400">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="w-4 h-4">
    <circle cx="12" cy="12" r="5" fill="currentColor" />
</svg>

            </div>
            {content?.AkuRedresor?.[28]}        </dt>
        </div>
      </dl>
    </div>
  </div>
</div>
<div class="bg-white py-24 sm:py-32">
  <div class="mx-auto max-w-7xl px-6 lg:px-8">
    <div class="mx-auto max-w-2xl lg:text-center">
      <p class="mt-2 text-3xl font-bold tracking-tight text-yellow-400 drop-shadow-[0_1.2px_1.2px_rgba(0,0,0,0.8)] sm:text-4xl">ASC 3110 30A MultiMax-T</p>
    </div>
    <div class="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-4xl">
      <dl class="grid max-w-xl grid-cols-1 gap-x-8 gap-y-10 lg:max-w-none lg:grid-cols-2 lg:gap-y-16">
        <div class="relative pl-16">
          <dt class="text-base font-semibold leading-7 text-gray-900">
            <div class="absolute left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg bg-yellow-400">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="w-4 h-4">
    <circle cx="12" cy="12" r="5" fill="currentColor" />
</svg>

            </div>
            {content?.AkuRedresor?.[29]}       </dt>
        </div>
        <div class="relative pl-16">
          <dt class="text-base font-semibold leading-7 text-gray-900">
            <div class="absolute left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg bg-yellow-400">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="w-4 h-4">
    <circle cx="12" cy="12" r="5" fill="currentColor" />
</svg>

            </div>
			{content?.AkuRedresor?.[30]}     </dt>
        </div>
        <div class="relative pl-16">
          <dt class="text-base font-semibold leading-7 text-gray-900">
            <div class="absolute left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg bg-yellow-400">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="w-4 h-4">
    <circle cx="12" cy="12" r="5" fill="currentColor" />
</svg>

            </div>
			{content?.AkuRedresor?.[31]}        </dt>
        </div>
        <div class="relative pl-16">
          <dt class="text-base font-semibold leading-7 text-gray-900">
            <div class="absolute left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg bg-yellow-400">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="w-4 h-4">
    <circle cx="12" cy="12" r="5" fill="currentColor" />
</svg>

            </div>
			{content?.AkuRedresor?.[32]}        </dt>
        </div>
      </dl>
    </div>
  </div>
</div>
    </div>
    
<div className='grid  lg:grid-cols-2 lg:items-center  lg:container mx-auto '>
        
<table class="border-collapse border border-slate-400 text-center  mb-12 lg:text-xl text-sm">
  <thead>
    <tr>
      <th colSpan={2} class="border border-slate-300 ...">{content?.AkuRedresor?.[33]} </th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td class="border border-slate-300 ...">{content?.AkuRedresor?.[34]} </td>
      <td class="border border-slate-300 ...">230V AC, 50Hz</td>
    </tr>
    <tr>
      <td class="border border-slate-300 ...">{content?.AkuRedresor?.[35]}  	</td>
      <td class="border border-slate-300 ...">{content?.AkuRedresor?.[36]} </td>
    </tr>
    <tr>
      <td class="border border-slate-300 ...">{content?.AkuRedresor?.[37]} 		</td>
      <td class="border border-slate-300 ...">{content?.AkuRedresor?.[38]} </td>
    </tr>
	<tr>
      <td class="border border-slate-300 ...">{content?.AkuRedresor?.[39]} 		</td>
      <td class="border border-slate-300 ...">{content?.AkuRedresor?.[40]} </td>
    </tr>
	<tr>
      <td class="border border-slate-300 ...">{content?.AkuRedresor?.[41]} 	</td>
      <td class="border border-slate-300 ...">{content?.AkuRedresor?.[42]} </td>
    </tr>
	<tr>
      <td class="border border-slate-300 ...">{content?.AkuRedresor?.[43]} 	</td>
      <td class="border border-slate-300 ...">	{content?.AkuRedresor?.[44]} </td>
    </tr>
	<tr>
      <td class="border border-slate-300 ...">{content?.AkuRedresor?.[45]} 		</td>
      <td class="border border-slate-300 ...">{content?.AkuRedresor?.[46]} </td>
    </tr>
	<tr>
      <td class="border border-slate-300 ...">{content?.AkuRedresor?.[47]}</td>
      <td class="border border-slate-300 ...">{content?.AkuRedresor?.[48]}</td>
    </tr>
	<tr>
      <td class="border border-slate-300 ...">{content?.AkuRedresor?.[49]}</td>
      <td class="border border-slate-300 ...">{content?.AkuRedresor?.[50]}</td>
    </tr>
	<tr>
      <td class="border border-slate-300 ...">{content?.AkuRedresor?.[51]}	</td>
      <td class="border border-slate-300 ...">{content?.AkuRedresor?.[52]}</td>
    </tr>
	<tr>
      <td class="border border-slate-300 ...">{content?.AkuRedresor?.[53]}	</td>
      <td class="border border-slate-300 ...">{content?.AkuRedresor?.[54]}</td>
    </tr>
	<tr>
		<td class="border border-slate-300 ...">{content?.AkuRedresor?.[55]}	</td>
      <td class="border border-slate-300 ...">{content?.AkuRedresor?.[56]}</td>
    </tr>
	<tr>
		<td class="border border-slate-300 ...">{content?.AkuRedresor?.[57]}	</td>
    	<td class="border border-slate-300 ...">{content?.AkuRedresor?.[58]}</td>
    </tr>

	<tr>
		<td class="border border-slate-300 ...">{content?.AkuRedresor?.[59]}	</td>
    	<td class="border border-slate-300 ...">-10⁰C / +55⁰C	</td>
    </tr>
	<tr>
		<td class="border border-slate-300 ...">{content?.AkuRedresor?.[60]}	</td>
    	<td class="border border-slate-300 ...">9,5kg /10,9kg/16,3kg</td>
    </tr>
  </tbody>
</table>
      <div className='h-[300px] w-[300px] my-auto   mx-auto border border-yellow-400'>
        <img src="/images/elektrik/aku-redresor/2.jpg" alt=""  className='w-full h-full mx-auto'/>
      </div>
    
      </div>
</section>
<div className='py-12'>
<center>
            <button 
                	onClick={()=>{
                    setIsOpenContantPage(true)
                  }}type="button" className="px-8 py-3 bg-gray-800 text-yellow-400 font-semibold border rounded border-gray-700  hover:bg-yellow-400 hover:text-gray-800  transition-all duration-200">{content?.ElectricalMaterial?.[8]}</button>
          </center>
      </div>

    </motion.div>
  )
}

export default AkuRedresor