import React from "react";
import { BsFillPlayCircleFill } from "react-icons/bs";

import  {motion} from "framer-motion"

const EnerjiDepolama = ({content}) => {
	console.log(content)
    return (
      <motion.div 
        initial={{ scale: 0.7}}
        animate={{ scale: 1}}
        transition={{duration: 0.5}}
      className="about-section-container flex my-auto w-screen mb-24 ">
        <div className="about-background-image-container absolute z-20">
          <img src="/about-background.png" alt="" />
        </div>
        <section className="bg-gray-800 text-gray-100 mt-32 mx-auto  relative z-20">
		<div className="container max-w-xl p-6 py-12 mx-auto space-y-24 lg:px-8 lg:max-w-7xl">
			<div>
				<h2 className="text-3xl font-bold tracki text-center sm:text-5xl text-gray-50">{content?.EnergyStore?.[0]}</h2>
				<p className="max-w-3xl mx-auto mt-4 text-xl text-center text-gray-400"> {content?.EnergyStore?.[1]}</p>
			</div>
			<div className="grid lg:gap-8 lg:grid-cols-2 lg:items-center">
				<div>
					<h3 className="text-2xl font-bold tracki sm:text-3xl text-gray-50">{content?.EnergyStore?.[2]}</h3>
					<p className="mt-3 text-lg text-white">{content?.EnergyStore?.[3]} 
</p>
					
                    <p className="mt-3 text-lg text-white">
					{content?.EnergyStore?.[4]}
                    </p>
				</div>
				<div aria-hidden="true" className="mt-10 lg:mt-0">
					<img src="/enerji-depo-1.jpg" alt="" className="mx-auto rounded-lg shadow-lg bg-gray-500" />
				</div>
			</div>
            <div className="p-6 py-12 bg-yellow-400 text-gray-900">
	<div className="container mx-auto">
		<div className="flex flex-col lg:flex-row items-center justify-between">
			<h2 className="text-center text-3xl tracki font-bold">
			{content?.EnergyStore?.[5]}			</h2>
		
		</div>
	</div>
</div>
			<div>
				<div className="grid lg:gap-8 lg:grid-cols-2 lg:items-center">
					<div className="lg:col-start-2">
						<h3 className="text-2xl font-bold tracki sm:text-3xl dark:text-gray-50">{content?.EnergyStore?.[6]}</h3>
						<p className="mt-3 text-lg dark:text-gray-400">{content?.EnergyStore?.[7]}</p>
						<div className="mt-12 space-y-12">
							
						
							
						</div>
					</div>
					<div className="mt-10 lg:mt-0 lg:col-start-1 lg:row-start-1">
						<img src="/enerji-depo-2.jpg" alt="" className="mx-auto rounded-lg shadow-lg dark:bg-gray-500" />
					</div>
				</div>
			</div>
            <div className="container max-w-xl p-6 mx-auto space-y-24 lg:px-8 lg:max-w-7xl">
	
			<div className="grid lg:gap-8 lg:grid-cols-2 lg:items-center">
				<div>
					<h3 className="text-2xl font-bold tracki sm:text-3xl text-gray-50"> {content?.EnergyStore?.[8]}</h3>
				
					
                    <p className="mt-3 text-lg text-white">
					{content?.EnergyStore?.[9]}                    </p>
				</div>
				<div aria-hidden="true" className="mt-10 lg:mt-0">
					<img src="/enerji-depo-3.jpg" alt="" className="mx-auto rounded-lg shadow-lg bg-gray-500" />
				</div>
			</div>
            </div>
		</div>
	</section>
       
      </motion.div>
    );
  };
  
  export default EnerjiDepolama;
  