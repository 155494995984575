import React from 'react';

const Hero = ({content}) => {
  return (
    <div id="hero-bg" className='text-white  '>
      <div className='max-w-[800px]  w-full h-screen mx-auto text-center flex flex-col  border-yellow-400 pt-0 mt-[82px]'>
        
        <h1 className='md:text-4xl sm:text-6xl text-2xl font-bold text-neutral-400  sm:pt-40 pt-24'>
        {content?.Home?.HeroTitles?.[1]}

        </h1>
        <div className='flex justify-center items-center'>
         
        </div>
        <p className='md:text-xl text-md font-bold text-yellow-400'>      
        <p className='text-yellow-400 font-bold p-2 '>
        {content?.Home?.HeroTitles?.[0]}
        </p>
</p>
      </div>
    </div>
  );
};

export default Hero;
