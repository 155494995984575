import React, { useRef, useState, useEffect } from 'react';
import DetailsThumb from './DetailsThumb';
import "./og.css"
import {TiTick} from "react-icons/ti"
import  {motion} from "framer-motion"
import { Link } from "react-router-dom";
import EnerjiIzlemeFeatures from "./EnerjiIzlemeFeatures"

export default function EnerjiIzleme({setIsOpenContantPage,content}) {
  var [products, setProducts] = useState([
    {
      "_id": "1",
      "title": "Enerji İzleme Rölesi",
      "src": [
        "/images/elektrik/enerji-izleme/x.jpg"
      ],
      "description": "Orta Gerilim Hücreleri"
    }
  ]);
  var [index, setIndex] = useState(0);

  const myRef = useRef(null);

  const handleTab = (index) => {
    setIndex(index);
  };

  useEffect(() => {
    if (myRef.current) {
      const images = myRef.current.children;
      for (let i = 0; i < images.length; i++) {
        images[i].className = images[i].className.replace("active", "");
      }
      images[index].className = "active";
    }
  }, [index]);

  useEffect(() => {
    if (myRef.current) {
      myRef.current.children[index].className = "active";
    }
  }, []);

    return(
      <motion.div 
      initial={{ translateX: "-100%"}}
      animate={{ translateX: "0"}}
      transition={{duration: 0.5}}
      className='w-screen border'>
        <div className='mt-24 w-full h-16 bg-yellow-400 sticky top-[88px] z-50'>
        <nav aria-label="breadcrumb" className="w-full p-4 ">
	<ol className="flex h-8 space-x-2">
		<li className="flex items-center">
      <Link to="/">
			<a rel="noopener noreferrer" href="#" title="Back to homepage" className="hover:underline">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5 pr-1 dark:text-gray-400">
					<path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"></path>
				</svg>
			</a>
      </Link>
		</li>
    <Link to="/elektrik-malzemeleri" className='flex items-center space-x-2'> 
			<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" aria-hidden="true" fill="currentColor" className="w-2 h-2 mt-1 transform rotate-90 fill-current dark:text-gray-600">
				<path d="M32 30.031h-32l16-28.061z"></path>
			</svg>
			<a rel="noopener noreferrer" href="#" className="flex items-center px-1 capitalize hover:underline font-medium">Elektrik Malzemeleri</a>
    </Link>
		<li className="flex items-center space-x-2">
			<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" aria-hidden="true" fill="currentColor" className="w-2 h-2 mt-1 transform rotate-90 fill-current dark:text-gray-600">
				<path d="M32 30.031h-32l16-28.061z"></path>
			</svg>
			<a rel="noopener noreferrer" href="#" className="flex items-center px-1 capitalize hover:underline font-medium">{content?.EnergyMonitoring?.[0]}</a>
		</li>

	</ol>
</nav>
        </div>

      <div className="app     mx-auto">

      <div className="mx-auto max-w-2xl sm:text-center ">
          <h2 className="text-3xl font-bold tracking-tight text-yellow-400 drop-shadow-[0_1.2px_1.2px_rgba(0,0,0,0.8)] sm:text-4xl pt-12">{content?.EnergyMonitoring?.[1]}   </h2>
          <p className="mt-6 text-lg leading-8 text-gray-600">
          {content?.EnergyMonitoring?.[2]}          </p>
        </div>
        {
          products.map(item =>(
            <div className="details" >
              <div className="big-img shadow-2xl shadow-gray-700 shadow max-h-[400px] my-auto  ">
                <img className='  bg-white' src={item.src[index]} alt=""/>
              </div>
              <div className="box font-medium">
                <div className="row">
                  <h2 className='font-bold text-yellow-400 drop-shadow-[0_1.2px_1.2px_rgba(0,0,0,0.8)] text-3xl'>Sobrano</h2>
                </div>
              
<table class="border-collapse border border-slate-400 text-center  mb-12 lg:text-xl text-sm w-full" >
  <thead>
    <tr>
      <th colSpan={2} class="border border-slate-300 p-4">{content?.EnergyMonitoring?.[3]} </th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td class="border border-slate-300 p-4">59  </td>
      <td class="border border-slate-300 p-4">{content?.EnergyMonitoring?.[4]}</td>
    </tr>
    <tr>
      <td class="border border-slate-300 p-4">27</td>
      <td class="border border-slate-300 p-4"> {content?.EnergyMonitoring?.[5]}</td>
    </tr>
    <tr>
      <td class="border border-slate-300 p-4">	81O </td>
      <td class="border border-slate-300 p-4">{content?.EnergyMonitoring?.[6]} </td>
    </tr>   
    <tr>
      <td class="border border-slate-300 p-4">810 </td>
      <td class="border border-slate-300 p-4">{content?.EnergyMonitoring?.[7]}</td>
    </tr>
    <tr>
      <td class="border border-slate-300 p-4">79 </td>
      <td class="border border-slate-300 p-4">{content?.EnergyMonitoring?.[8]}</td>
    </tr>
    <tr>
      <td class="border border-slate-300 p-4">81R</td>
      <td class="border border-slate-300 p-4">{content?.EnergyMonitoring?.[9]}</td>
    </tr>
  </tbody>
</table>
              
                
              </div>
          
            </div>
          ))
        }
        {
          products.map(item =>(
            <div className="details" >
           
              <div className="box font-medium">
               
              
<table class="border-collapse border border-slate-400 text-center  mb-12 lg:text-xl text-sm w-full" >
  <thead>
    <tr>
      <th colSpan={2} class="border border-slate-300 p-4">{content?.EnergyMonitoring?.[10]} </th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td class="border border-slate-300 p-4">{content?.EnergyMonitoring?.[11]} </td>
      <td class="border border-slate-300 p-4">{content?.EnergyMonitoring?.[12]}</td>
    </tr>
    <tr>
      <td class="border border-slate-300 p-4">{content?.EnergyMonitoring?.[13]}</td>
      <td class="border border-slate-300 p-4">{content?.EnergyMonitoring?.[14]} </td>
    </tr>
    <tr>
      <td class="border border-slate-300 p-4">{content?.EnergyMonitoring?.[15]}</td>
      <td class="border border-slate-300 p-4">{content?.EnergyMonitoring?.[16]} </td>
    </tr>   
    <tr>
      <td class="border border-slate-300 p-4">{content?.EnergyMonitoring?.[17]}</td>
      <td class="border border-slate-300 p-4">{content?.EnergyMonitoring?.[18]}</td>
    </tr>
    <tr>
      <td class="border border-slate-300 p-4">{content?.EnergyMonitoring?.[19]}</td>
      <td class="border border-slate-300 p-4">{content?.EnergyMonitoring?.[20]}</td>
    </tr>
  </tbody>
</table>
              </div>
              <div className="big-img  shadow-gray-700 shadow max-h-[400px] my-auto  ">
                <img className='  bg-white' src="/images/elektrik/enerji-izleme/1.jpg" alt=""/>
              </div>
            </div>
          ))
        }
        
        <div className=" text-gray-900">
	<div className="px-4 py-16 mx-auto max-w-7xl sm:px-6 lg:px-8">
		<div className="max-w-3xl mx-auto text-center">
			<h2 className="text-3xl font-extrabold text-yellow-400 drop-shadow-[0_1.2px_1.2px_rgba(0,0,0,0.8)] sm:text-4xl">{content?.EnergyMonitoring?.[64]}</h2>
		</div>
		<dl className="mt-12 space-y-10 sm:space-y-0 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:gap-y-12 lg:grid-cols-4 lg:gap-x-8">
			
      
      <div className="flex">
				<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true" className="flex-shrink-0 w-6 h-6 text-yellow-400">
					<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
				</svg>
				<div className="ml-3">
					<dt className="text-lg font-medium">{content?.EnergyMonitoring?.[21]}</dt>
				</div>
			</div>
			<div className="flex">
				<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true" className="flex-shrink-0 w-6 h-6 text-yellow-400 ">
					<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
				</svg>
				<div className="ml-3">
					<dt className="text-lg font-medium">{content?.EnergyMonitoring?.[22]}</dt>
				</div>
			</div>
			<div className="flex">
				<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true" className="flex-shrink-0 w-6 h-6 text-yellow-400">
					<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
				</svg>
				<div className="ml-3">
					<dt className="text-lg font-medium">{content?.EnergyMonitoring?.[23]}</dt>
				</div>
			</div>
			<div className="flex">
				<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true" className="flex-shrink-0 w-6 h-6 text-yellow-400">
					<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
				</svg>
				<div className="ml-3">
					<dt className="text-lg font-medium">{content?.EnergyMonitoring?.[24]}</dt>
				</div>
			</div>
			<div className="flex">
				<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true" className="flex-shrink-0 w-6 h-6 text-yellow-400">
					<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
				</svg>
				<div className="ml-3">
					<dt className="text-lg font-medium">{content?.EnergyMonitoring?.[25]} </dt>
				</div>
			</div>
			<div className="flex">
				<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true" className="flex-shrink-0 w-6 h-6 text-yellow-400">
					<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
				</svg>
				<div className="ml-3">
					<dt className="text-lg font-medium">{content?.EnergyMonitoring?.[26]}</dt>
				</div>
			</div>
			<div className="flex">
				<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true" className="flex-shrink-0 w-6 h-6 text-yellow-400">
					<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
				</svg>
				<div className="ml-3">
					<dt className="text-lg font-medium">{content?.EnergyMonitoring?.[27]}</dt>
				</div>
			</div>
			<div className="flex">
				<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true" className="flex-shrink-0 w-6 h-6 text-yellow-400">
					<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
				</svg>
				<div className="ml-3">
					<dt className="text-lg font-medium">{content?.EnergyMonitoring?.[28]} </dt>
				</div>
			</div>
      <div className="flex">
				<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true" className="flex-shrink-0 w-6 h-6 text-yellow-400">
					<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
				</svg>
				<div className="ml-3">
					<dt className="text-lg font-medium">{content?.EnergyMonitoring?.[29]}</dt>
				</div>
			</div>
      <div className="flex">
				<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true" className="flex-shrink-0 w-6 h-6 text-yellow-400">
					<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
				</svg>
				<div className="ml-3">
					<dt className="text-lg font-medium">{content?.EnergyMonitoring?.[30]} </dt>
				</div>
			</div>
      <div className="flex">
				<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true" className="flex-shrink-0 w-6 h-6 text-yellow-400">
					<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
				</svg>
				<div className="ml-3">
					<dt className="text-lg font-medium">{content?.EnergyMonitoring?.[31]} </dt>
				</div>
			</div>
      <div className="flex">
				<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true" className="flex-shrink-0 w-6 h-6 text-yellow-400">
					<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
				</svg>
				<div className="ml-3">
					<dt className="text-lg font-medium">{content?.EnergyMonitoring?.[32]}</dt>
				</div>
			</div>
		</dl>
	</div>

  {
          products.map(item =>(
            <div className="details" >
              <div className="big-img shadow-2xl shadow-gray-700 shadow max-h-[400px] my-auto  ">
                <img className='  bg-white' src="/images/elektrik/enerji-izleme/2.jpg" alt=""/>
              </div>
              <div className="box font-medium">
                <div className="row">
                  <h2 className='font-bold text-yellow-400 drop-shadow-[0_1.2px_1.2px_rgba(0,0,0,0.8)] text-3xl'>FANOX SIU C</h2>
                </div>
              
<table class="border-collapse border border-slate-400 text-center  mb-12 lg:text-xl text-sm w-full" >
  <thead>
    <tr>
      <th colSpan={2} class="border border-slate-300 p-4">{content?.EnergyMonitoring?.[53]} </th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td class="border border-slate-300 p-4">{content?.EnergyMonitoring?.[54]} 	   </td>
      <td class="border border-slate-300 p-4">{content?.EnergyMonitoring?.[55]} </td>
    </tr>
    <tr>
      <td class="border border-slate-300 p-4">{content?.EnergyMonitoring?.[56]}  </td>
      <td class="border border-slate-300 p-4">	{content?.EnergyMonitoring?.[57]} </td>
    </tr>
    <tr>
      <td class="border border-slate-300 p-4">		{content?.EnergyMonitoring?.[58]} </td>
      <td class="border border-slate-300 p-4">{content?.EnergyMonitoring?.[59]} </td>
    </tr>   
    <tr>
      <td class="border border-slate-300 p-4">{content?.EnergyMonitoring?.[60]}  </td>
      <td class="border border-slate-300 p-4"> 	{content?.EnergyMonitoring?.[61]} </td>
    </tr>
    <tr>
      <td class="border border-slate-300 p-4"> 	{content?.EnergyMonitoring?.[62]}  	</td>
      <td class="border border-slate-300 p-4">{content?.EnergyMonitoring?.[63]}  </td>
    </tr>
  </tbody>
</table>
              
                
              </div>
          
            </div>
          ))
        }
</div>
<div  className='md:px-48'>
<table class="border-collapse border border-slate-400 text-center  mb-12 lg:text-xl text-sm w-full " >
  <thead>
    <tr>
      <th colSpan={2} class="border border-slate-300 p-4">{content?.EnergyMonitoring?.[33]}  </th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td class="border border-slate-300 p-4">59		   </td>
      <td class="border border-slate-300 p-4">{content?.EnergyMonitoring?.[34]} </td>
    </tr>
    <tr>
      <td class="border border-slate-300 p-4">59-L* 	 </td>
      <td class="border border-slate-300 p-4">	{content?.EnergyMonitoring?.[35]} </td>
    </tr>
    <tr>
      <td class="border border-slate-300 p-4">		59N/G 	 	 </td>
      <td class="border border-slate-300 p-4">{content?.EnergyMonitoring?.[36]} </td>
    </tr>   
    <tr>
      <td class="border border-slate-300 p-4">47	 </td>
      <td class="border border-slate-300 p-4"> {content?.EnergyMonitoring?.[37]}  </td>
    </tr>
    <tr>
      <td class="border border-slate-300 p-4"> 	27		</td>
      <td class="border border-slate-300 p-4">{content?.EnergyMonitoring?.[38]}  </td>
    </tr>
    <tr>
      <td class="border border-slate-300 p-4"> 	27-L* 			</td>
      <td class="border border-slate-300 p-4">{content?.EnergyMonitoring?.[39]}  </td>
    </tr>
    <tr>
      <td class="border border-slate-300 p-4"> 	27V1			</td>
      <td class="border border-slate-300 p-4">{content?.EnergyMonitoring?.[40]}  </td>
    </tr>
    <tr>
      <td class="border border-slate-300 p-4"> 	dV/dt  		</td>
      <td class="border border-slate-300 p-4">{content?.EnergyMonitoring?.[41]} </td>
    </tr>
    <tr>
      <td class="border border-slate-300 p-4"> 	81O/U 		</td>
      <td class="border border-slate-300 p-4">{content?.EnergyMonitoring?.[42]} 	 </td>
    </tr>
    <tr>
      <td class="border border-slate-300 p-4"> 	81R 		</td>
      <td class="border border-slate-300 p-4">{content?.EnergyMonitoring?.[43]}   </td>
    </tr>
    <tr>
      <td class="border border-slate-300 p-4"> 	78		</td>
      <td class="border border-slate-300 p-4"> {content?.EnergyMonitoring?.[44]}   </td>
    </tr>
    <tr>
      <td class="border border-slate-300 p-4"> 	24	 		</td>
      <td class="border border-slate-300 p-4">{content?.EnergyMonitoring?.[45]}   </td>
    </tr>
    <tr>
      <td class="border border-slate-300 p-4"> 	79	 		</td>
      <td class="border border-slate-300 p-4">{content?.EnergyMonitoring?.[46]}   </td>
    </tr>
    <tr>
      <td class="border border-slate-300 p-4"> 	52	 	</td>
      <td class="border border-slate-300 p-4">{content?.EnergyMonitoring?.[47]} 	  </td>
    </tr>
    <tr>
      <td class="border border-slate-300 p-4"> 	25* 			</td>
      <td class="border border-slate-300 p-4">{content?.EnergyMonitoring?.[48]}  </td>
    </tr>
    <tr>
      <td class="border border-slate-300 p-4"> 	74TCS 			</td>
      <td class="border border-slate-300 p-4">{content?.EnergyMonitoring?.[49]}   </td>
    </tr>
    <tr>
      <td class="border border-slate-300 p-4"> 	74CCS 			</td>
      <td class="border border-slate-300 p-4">{content?.EnergyMonitoring?.[50]}   </td>
    </tr>
    <tr>
      <td class="border border-slate-300 p-4"> 	49T 	</td>
      <td class="border border-slate-300 p-4">	{content?.EnergyMonitoring?.[51]} 	  </td>
    </tr>
    <tr>
      <td class="border border-slate-300 p-4"> 	86			</td>
      <td class="border border-slate-300 p-4">{content?.EnergyMonitoring?.[52]}   </td>
    </tr>
 
  </tbody>
</table>
</div>
      </div>
    
      <div className='py-12'>
          <center>
            <button
          	onClick={()=>{
              setIsOpenContantPage(true)
            }}
            type="button" className="px-8 py-3 bg-gray-800 text-yellow-400 font-semibold border rounded border-gray-700  hover:bg-yellow-400 hover:text-gray-800  transition-all duration-200">{content?.ElectricalMaterial?.[8]}</button>
          </center>
      </div>
      </motion.div>
  );
};

