import React from 'react'
import {AiFillCloseSquare} from 'react-icons/ai'
import {MdOutlineLocationOn ,MdOutlineEmail} from 'react-icons/md'
import {BsFillTelephoneFill} from 'react-icons/bs'
import  {motion} from "framer-motion"
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Contact = ({setIsOpenContantPage,content}) => {

	const sendEmail = (e) => {
        e.preventDefault(); // Olayın yayılmasını engelle

        var firm = document.getElementById('firm').value
        var message = document.getElementById('message').value
        var name = document.getElementById('name').value
        var email = document.getElementById('email').value
        toast.success('Mesajınız Alındı.', {
            toastId: 'success1',
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            });
        axios.post("https://deelektrikmail.netlify.app/.netlify/functions/api/add",{
            name:name,
            email:email,
            message:message,
            firm:firm
        }).then((response) => {
                
      
        });
      };
  return (
<div>
    <ToastContainer
    position="top-right"
    autoClose={5000}
    hideProgressBar={false}
    newestOnTop={false}
    closeOnClick
    rtl={false}
    pauseOnFocusLoss
    draggable
    pauseOnHover
    theme="light"
    />
    {/* Same as */}
    <ToastContainer />

    <motion.div
        initial={{scale:0.5}}
        animate={{scale:1}}
        transition={{duration:0.4}}
        
    className='w-screen   h-fit  	absolute  bg-gray-900/90 text-white  '>


        <section className="py-6   text-gray-50 h-fit">
        <span className="block mb-20 text-xs  tracki text-center  text-white mt-12 text-[50px]"><span className='text-[64px] font-extrabold' > {content?.ContactForm?.[0]} </span> < span className='text-[30px] font-semibold'>{content?.ContactForm?.[7]} </span> </span>
        <div 
            className='absolute sm:right-8 sm:top-8 top-0 right-0 border-double text-yellow-400 cursor-pointer'
            onClick={() => setIsOpenContantPage(false)}><AiFillCloseSquare size={64}/></div>
        <div className='grid  grid-cols-1 gap-0 h-fit '>
        <div className=" text-gray-100 flex-1 mx-auto  w-auto ">
                <form novalidate="" className="container w-auto max-w-xl p-8 space-y-6 rounded-md shadow bg-gray-900">
                    <h2 className="w-full text-3xl font-bold leadi text-center">DE ENERJİ DANIŞMANLIK LTD.ŞTİ.</h2>
                  
                   <div>
                        <div className='flex'> <span className=' '><MdOutlineLocationOn  className='mr-1' size={40}/></span> <span className='ml-4'>Ugur Mumcu Mah. Rajiv Gandi Cad. 1649 Sk. No: 2G Yenimahalle/Ankara-Türkiye</span></div>
                   </div>
                   <div>
                        <div className='flex'> <span className=' '><BsFillTelephoneFill className='mr-6 ml-2' size={25}/></span> <span>+90 533 764 40 22</span></div>
                   </div>
                   <div>
                        <div className='flex'> <span className=' '><MdOutlineEmail className='mr-6 ml-2' size={25}/></span> <span>info@deenerji.com.tr</span></div>
                   </div>
                   <div className="p-6 text-gray-100 flex-1  mr-auto w-auto xl:w-full md:w-[500px] ">
            <form novalidate="" className=" w-full max-w-xl p-8 pt-0   space-y-6 rounded-md shadow bg-gray-900">
                <div>
                    <label for="name" className="block mb-1 ml-1">{content?.ContactForm?.[1]}</label>
                    <input id="name" type="text" placeholder={content?.ContactForm?.[4]} required="" className="block w-full p-2 rounded focus:outline-none focus:ring focus:ri focus:ri bg-gray-800" />
                </div>
                <div>
                    <label for="email" className="block mb-1 ml-1">{content?.ContactForm?.[2]}</label>
                    <input id="email" type="email" placeholder={content?.ContactForm?.[5]} required="" className="block w-full p-2 rounded focus:outline-none focus:ring focus:ri focus:ri bg-gray-800" />
                </div>
                <div>
                    <label for="firm" className="block mb-1 ml-1">{content?.ContactForm?.[9]}</label>
                    <input id="firm" type="text" placeholder={content?.ContactForm?.[10]} required="" className="block w-full p-2 rounded focus:outline-none focus:ring focus:ri focus:ri bg-gray-800" />
                </div>
                <div>
                    <label for="message" className="block mb-1 ml-1">{content?.ContactForm?.[3]}</label>
                    <textarea id="message" type="text" rows={6} placeholder={content?.ContactForm?.[6]} className="block w-full p-2 rounded autoexpand focus:outline-none focus:ring focus:ri focus:ri bg-gray-800"></textarea>
                </div>
  
                <div>
                    <button onClick={sendEmail}   className="w-full px-4 py-2 font-bold rounded shadow focus:outline-none focus:ring hover:ring focus:ri bg-yellow-400 focus:ri hover:ri text-gray-900">{content?.ContactForm?.[8]}</button>
                </div>
            </form>
            </div>
        
                </form>
                
            </div>

       



</div>

</section>
    </motion.div>
    </div>
  )
}

export default Contact