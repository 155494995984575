import React, { useRef, useState, useEffect } from 'react';
import DetailsThumb from '../DetailsThumb';
import "../og.css"
import {TiTick} from "react-icons/ti"
import  {motion} from "framer-motion"
import { Link } from "react-router-dom";
import { CheckIcon } from '@heroicons/react/20/solid'

export default function AGParafudr({setIsOpenContantPage,content}) {
  var [products, setProducts] = useState([
    {
      "_id": "1",
      "title": "AG Panolar",
      "src": [
        "/images/elektrik/ag/Akıllı AG Panolar.jpg",
        "/images/elektrik/ag/AG_Pano-1.jpeg",
        "/images/elektrik/ag/AG_Pano-2.jpeg",
        "/images/elektrik/ag/AG_Pano-3.jpeg",
        "/images/elektrik/ag/x.jpg",
      ],
      "description": "Lokal, özel veya TEDAŞ panolar"
    }
  ]);
  var [index, setIndex] = useState(0);

  const myRef = useRef(null);

  const handleTab = (index) => {
    setIndex(index);
  };

  useEffect(() => {
    if (myRef.current) {
      const images = myRef.current.children;
      for (let i = 0; i < images.length; i++) {
        images[i].className = images[i].className.replace("active", "");
      }
      images[index].className = "active";
    }
  }, [index]);

  useEffect(() => {
    if (myRef.current) {
      myRef.current.children[index].className = "active";
    }
  }, []);

    return(
      <motion.div 
      initial={{ translateX: "-100%"}}
      animate={{ translateX: "0"}}
      transition={{duration: 0.5}}
      className='w-screen border'>
        <div className='mt-24 w-full h-16 bg-yellow-400 sticky top-[88px] z-50'>
        <nav aria-label="breadcrumb" className="w-full p-4 ">
	<ol className="flex h-8 space-x-2">
		<li className="flex items-center">
      <Link to="/">
			<a rel="noopener noreferrer" href="#" title="Back to homepage" className="hover:underline">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5 pr-1 dark:text-gray-400">
					<path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"></path>
				</svg>
			</a>
      </Link>
		</li>
    <Link to="/elektrik-malzemeleri" className='flex items-center space-x-2'> 
			<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" aria-hidden="true" fill="currentColor" className="w-2 h-2 mt-1 transform rotate-90 fill-current dark:text-gray-600">
				<path d="M32 30.031h-32l16-28.061z"></path>
			</svg>
			<a rel="noopener noreferrer" href="#" className="flex items-center px-1 capitalize hover:underline font-medium">Elektrik Malzemeleri</a>
    </Link>
		<li className="flex items-center space-x-2">
			<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" aria-hidden="true" fill="currentColor" className="w-2 h-2 mt-1 transform rotate-90 fill-current dark:text-gray-600">
				<path d="M32 30.031h-32l16-28.061z"></path>
			</svg>
			<a rel="noopener noreferrer" href="#" className="flex items-center px-1 capitalize hover:underline font-medium">{content?.AGParafudr?.[0]}</a>
		</li>

	</ol>
</nav>
        </div>
        
        <section className=" text-gray-900 w-screen ">
	<div className="container max-w-xl p-6  mx-auto  lg:px-32 lg:max-w-7xl ">
		<div className=''> 
			<h2 className="text-3xl font-bold tracki text-center sm:text-5xl text-yellow-400 drop-shadow-[0_1.2px_1.2px_rgba(0,0,0,0.8)]">{content?.AGParafudr?.[0]}</h2>
			<p className="mt-6 text-lg leading-8 text-gray-600 text-center">
			{content?.AGParafudr?.[1]}
          </p>
		</div>

		<div className='   '>
			<div className="grid lg:gap-32 lg:grid-cols-2 lg:items-center">
				<div className="lg:col-start-2">
					<div className="mt-12 space-y-6">
						<div className="flex">
							<div className="flex-shrink-0">
								<div className="flex items-center justify-center w-6 h-6 rounded-md bg-yellow-400 text-gray-900">
									<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="w-4 h-4">
										<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
									</svg>
								</div>
							</div>
							<div className="ml-4">
								<h4 className="text-lg font-medium leadi text-gray-950">{content?.AGParafudr?.[2]}</h4>
							</div>
						</div>
						<div className="flex">
							<div className="flex-shrink-0">
								<div className="flex items-center justify-center w-6 h-6 rounded-md bg-yellow-400 text-gray-900">
									<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="w-4 h-4">
										<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
									</svg>
								</div>
							</div>
							<div className="ml-4">
								<h4 className="text-lg font-medium leadi text-gray-950">{content?.AGParafudr?.[3]}</h4>
							</div>
						</div>
						<div className="flex">
							<div className="flex-shrink-0">
								<div className="flex items-center justify-center w-6 h-6 rounded-md bg-yellow-400 text-gray-900">
									<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="w-4 h-4">
										<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
									</svg>
								</div>
							</div>
							<div className="ml-4">
								<h4 className="text-lg font-medium leadi text-gray-950">{content?.AGParafudr?.[4]}</h4>
							</div>
						</div>
						<div className="flex">
							<div className="flex-shrink-0">
								<div className="flex items-center justify-center w-6 h-6 rounded-md bg-yellow-400 text-gray-900">
									<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="w-4 h-4">
										<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
									</svg>
								</div>
							</div>
							<div className="ml-4">
								<h4 className="text-lg font-medium leadi text-gray-950">{content?.AGParafudr?.[5]}</h4>
							</div>
						</div>
						<div className="flex">
							<div className="flex-shrink-0">
								<div className="flex items-center justify-center w-6 h-6 rounded-md bg-yellow-400 text-gray-900">
									<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="w-4 h-4">
										<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
									</svg>
								</div>
							</div>
							<div className="ml-4">
								<h4 className="text-lg font-medium leadi text-gray-950">{content?.AGParafudr?.[6]}</h4>
							</div>
						</div>
						<div className="flex">
							<div className="flex-shrink-0">
								<div className="flex items-center justify-center w-6 h-6 rounded-md bg-yellow-400 text-gray-900">
									<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="w-4 h-4">
										<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
									</svg>
								</div>
							</div>
							<div className="ml-4">
								<h4 className="text-lg font-medium leadi text-gray-950">{content?.AGParafudr?.[7]}</h4>
							</div>
						</div>
						<div className="flex">
							<div className="flex-shrink-0">
								<div className="flex items-center justify-center w-6 h-6 rounded-md bg-yellow-400 text-gray-900">
									<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="w-4 h-4">
										<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
									</svg>
								</div>
							</div>
							<div className="ml-4">
								<h4 className="text-lg font-medium leadi text-gray-950">{content?.AGParafudr?.[8]}</h4>
							</div>
						</div>
						<div className="flex">
							<div className="flex-shrink-0">
								<div className="flex items-center justify-center w-6 h-6 rounded-md bg-yellow-400 text-gray-900">
									<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="w-4 h-4">
										<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
									</svg>
								</div>
							</div>
							<div className="ml-4">
								<h4 className="text-lg font-medium leadi text-gray-950">{content?.AGParafudr?.[9]}</h4>
							</div>
						</div>
						
					</div>
				</div>
				<div className="mt-10 lg:mt-0 lg:col-start-1 lg:row-start-1">
					<img src="/images/elektrik/ag-parafudr/0.jpg" alt="" className="mx-auto rounded-lg shadow-lg bg-gray-500" />
				</div>
			</div>
		</div>
	</div>
</section>
<div  className='md:px-48'>
<table class="border-collapse border border-slate-400 text-center  mb-12 lg:text-xl text-xs w-full " >
  <thead>
    <tr>
      <th colSpan={5} class="border border-slate-300 md:p-4 p-0">{content?.AGParafudr?.[10]}  </th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td colSpan={2} class="border border-slate-300 p-4 "></td>
      <td colSpan={1} class="border border-slate-300 p-4 ">SRG T1 3P+N 150		   </td>
      <td colSpan={1} class="border border-slate-300 p-4 ">SRG T1 3P+N 150</td>
      <td colSpan={1} class="border border-slate-300 p-4 ">SRG T1 3P+N 150</td>
    </tr>
    <tr>
      <td class="border border-slate-300 p-4 ">{content?.AGParafudr?.[11]} 	 </td>
      <td class="border border-slate-300 p-4 ">	Un</td>
	  <td colSpan={1} class="border border-slate-300 p-4 ">130V		   </td>
      <td colSpan={1} class="border border-slate-300 p-4 ">240V</td>
      <td colSpan={1} class="border border-slate-300 p-4 ">230V</td>
    </tr>
	<tr>
      <td class="border border-slate-300 p-4">{content?.AGParafudr?.[12]}</td>
      <td class="border border-slate-300 p-4">	Uc</td>
	  <td colSpan={1} class="border border-slate-300 p-4">150V		   </td>
      <td colSpan={1} class="border border-slate-300 p-4">275V</td>
      <td colSpan={1} class="border border-slate-300 p-4">385V</td>
    </tr>
	<tr>
      <td class="border border-slate-300 p-4">{content?.AGParafudr?.[13]}</td>
      <td class="border border-slate-300 p-4">	In</td>
	  <td colSpan={3} class="border border-slate-300 p-4">50kA (L-N) /100kA (N-PE)		   </td>
	  </tr>
	  <tr>
      <td class="border border-slate-300 p-4">{content?.AGParafudr?.[14]}</td>
      <td class="border border-slate-300 p-4">	Imax</td>
	  <td colSpan={3} class="border border-slate-300 p-4">70kA (L-N) /160kA (N-PE)		   </td>
	  </tr>
	  <tr>
      <td class="border border-slate-300 p-4">{content?.AGParafudr?.[15]}</td>
      <td class="border border-slate-300 p-4">	limp</td>
	  <td colSpan={3} class="border border-slate-300 p-4">50kA (L-N) /100kA (N-PE)	   </td>
	  </tr>
	  <tr>
      <td class="border border-slate-300 p-4">{content?.AGParafudr?.[16]}</td>
      <td class="border border-slate-300 p-4">	Up</td>
	  <td colSpan={1} class="border border-slate-300 p-4">≤ 0,8kV		   </td>
      <td colSpan={1} class="border border-slate-300 p-4">≤ 1,3kV</td>
      <td colSpan={1} class="border border-slate-300 p-4">≤ 1,8kV</td>
    </tr>
	<tr>
      <td class="border border-slate-300 p-4">{content?.AGParafudr?.[17]}</td>
      <td class="border border-slate-300 p-4">	tA</td>
	  <td colSpan={3} class="border border-slate-300 p-4">≤25ns / ≤100ms</td>
    </tr>
	<tr>
      <td class="border border-slate-300 p-4">{content?.AGParafudr?.[18]}</td>
      <td class="border border-slate-300 p-4">	</td>
	  <td colSpan={3} class="border border-slate-300 p-4">315 A gL/gG</td>
    </tr>
	<tr>
      <td class="border border-slate-300 p-4">{content?.AGParafudr?.[19]}</td>
      <td class="border border-slate-300 p-4">	UT</td>
	  <td colSpan={1} class="border border-slate-300 p-4">235V		   </td>
      <td colSpan={1} class="border border-slate-300 p-4">440V</td>
      <td colSpan={1} class="border border-slate-300 p-4">526V</td>
    </tr>
	
	<tr>
      <td class="border border-slate-300 p-4">{content?.AGParafudr?.[20]}</td>
      <td class="border border-slate-300 p-4">	UT</td>
	  <td colSpan={3} class="border border-slate-300 p-4">1200V</td>
    </tr>
	<tr>
      <td class="border border-slate-300 p-4">{content?.AGParafudr?.[21]}</td>
      <td class="border border-slate-300 p-4">	Ifi</td>
	  <td colSpan={3} class="border border-slate-300 p-4">100 ARMS</td>
    </tr>
  </tbody>
</table>
</div>
				<div className='py-12'>
				<center>
            <button 
                	onClick={()=>{
                    setIsOpenContantPage(true)
                  }}type="button" className="px-8 py-3 bg-gray-800 text-yellow-400 font-semibold border rounded border-gray-700  hover:bg-yellow-400 hover:text-gray-800  transition-all duration-200">{content?.ElectricalMaterial?.[8]}</button>
          </center>
      </div>
      </motion.div>
  );
};

