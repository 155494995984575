import React from 'react'

const MobilityTable = ({content}) => {
    console.log(content)
  return (
    <div>
      
    <div class="relative   shadow-md sm:rounded-lg parent">
        
    <article className="max-w-2xl px-6 pt-24 mx-auto space-y-12  text-gray-800">
	<div className="w-full mx-auto space-y-4 text-center">
		<h1 className="text-4xl font-bold leadi md:text-5xl">{content?.EMobil?.[10]}</h1>
	
	</div>
	<div className="text-gray-900 text-center">
		<p>{content?.EMobil?.[11]}
</p>
	</div>

</article>
  
<div className='lg:flex '>
      <table class=" w-[250px] sm:text-lg text-[8px]   text-gray-500  mb-4 container lg:mr-0 mr-auto ml-auto">
        
          <caption class="   text-left p-5  text-sm sm:font-semibold font-thin  text-gray-900 bg-white  ">
          {content?.EMobil?.[12]}
          </caption>

            <tbody class="">
              <tr class=" ">
                  <th scope="row" class="sm:px-6 py-4 font-medium  whitespace-nowrap   text-grey-800 border border-gray-700 border-l-0  ">
                    3,7kW
                  </th>
                  <th rowspan="2" className=' sm:px-6 mx-auto border-gray-700 border font-mono antialiased  text-center   '>
                  {content?.EMobil?.[13]}
                  </th>
                  <th rowspan="2" className=' sm:px-6 mx-auto border-gray-700 border font-mono antialiased  text-center   '>
                  {content?.EMobil?.[14]}
                  </th>
                  <th rowspan="4" className='sm:px-6 mx-auto border-gray-700 border font-mono antialiased  text-center   '>
                  {content?.EMobil?.[15]}
                  </th>
                  
                </tr>
                <tr class=" border-b  border-gray-700">
                    <th  scope="row" class="sm:px-6 py-3  font-mono antialiased  ">
                        7,5 kW
                    </th>
                  
                </tr>

                <tr class=" border-b  border-gray-700">
          
                    <th  scope="col" class="sm:px-6 py-3  font-mono antialiased  ">
                        11kW
                    </th>
                    <th rowspan="2" className='sm:px-6 mx-auto border-gray-700 border font-mono antialiased  text-center   '>
                    {content?.EMobil?.[19]}
                  </th>
                  <th rowspan="2" className=' sm:px-6mx-auto border-gray-700 border font-mono antialiased  text-center   '>
                  {content?.EMobil?.[16]}                  </th>
                </tr>                
                <tr class=" border-b  border-gray-700">
                    <th  scope="col" class="sm:px-6 py-3  font-mono antialiased  ">
                        22kW
                    </th>
                   
                </tr>
                <tr className='border-b border-gray-700'>
                <th  scope="col" class="sm:px-6 py-3  font-mono antialiased  ">
                        2x3,7kW
                    </th>
                    <th rowspan="2" className='sm:px-6 mx-auto border-gray-700 border font-mono antialiased  text-center   '>
                    {content?.EMobil?.[13]}
                  </th>
                  <th rowspan="2" className='sm:px-6 mx-auto border-gray-700 border font-mono antialiased  text-center   '>
                  {content?.EMobil?.[17]}                  </th>  <th rowspan="4" className=' sm:px-6 mx-auto border-gray-700 border font-mono antialiased  text-center   '>
                  {content?.EMobil?.[18]}  
                  </th>
                </tr>
                <tr className='border-b border-gray-700'>
                <th  scope="col" class="sm:px-6 py-3  font-mono antialiased  ">
                        2x7,4kW
                    </th>
                   
                </tr>
                <tr class=" border-b  border-gray-700">
          
          <th  scope="col" class="sm:px-6 py-3  font-mono antialiased  ">
              2x11kW
          </th>
          <th rowspan="2" className=' sm:px-6 mx-auto border-gray-700 border font-mono antialiased  text-center   '>
          {content?.EMobil?.[19]}
        </th>
        <th rowspan="2" className=' sm:px-6 mx-auto border-gray-700 border font-mono antialiased  text-center   '>
        {content?.EMobil?.[20]}        </th>
      </tr>        
      <tr>
      <th  scope="col" class="sm:px-6 py-3  font-mono antialiased border-gray-700 border border-l-0  ">
              2x22kW
          </th>
      </tr>
            </tbody>
      </table>
      <div className='h-[300px] w-[300px] my-auto  lg:ml-0 mr-auto ml-auto'>
        <img src="/36.png" alt=""  className='w-full h-full'/>
      </div>
    
      </div>
   

  <article className="max-w-2xl px-6 pt-24 mx-auto space-y-12  text-gray-800">
	<div className="w-full mx-auto space-y-4 text-center">
		<h1 className="text-4xl font-bold leadi md:text-5xl">{content?.EMobil?.[21]}</h1>
	
	</div>
	<div className="text-gray-900 text-center">
		<p>{content?.EMobil?.[22]}
</p>
	</div>

</article>
<div className='lg:flex '>
      <table class=" sm:w-[350px] w-[200px]  sm:text-lg text-[8px]   text-gray-500  mb-4 container lg:mr-0 mr-auto ml-auto">
        
          <caption class="   text-left p-5  text-sm sm:font-semibold font-thin  text-gray-900 bg-white  ">
          {content?.EMobil?.[23]}          </caption>

            <tbody class="">
              <tr class=" ">
                  <th scope="row" class="sm:px-6 py-4 font-medium  whitespace-nowrap   text-grey-800 border border-gray-700 border-l-0  ">
                    30kW
                  </th>
                  <th rowspan="3" className=' sm:px-6 mx-auto text-[15px] border-gray-700 border font-mono antialiased  text-center   '>
                  {content?.EMobil?.[24]}                  </th>
                </tr>
                <tr class=" border-b  border-gray-700">
                    <th  scope="row" class="sm:px-6 py-3  font-mono antialiased  ">
                        60kW
                    </th>
                </tr>
                <tr class=" border-b  border-gray-700">
                    <th  scope="row" class="sm:px-6 py-3  font-mono antialiased  ">
                        90kW
                    </th>
                </tr>
                <tr class=" border-b  border-gray-700">
          
                    <th  scope="col" class="sm:px-6 py-3  font-mono antialiased  ">
                        120kW
                    </th>
                    <th rowspan="6" className='sm:px-6 mx-auto text-[15px] border-gray-700 border font-mono antialiased  text-center   '>
                    {content?.EMobil?.[25]}
                  </th>
               
                </tr>                
                <tr class=" border-b  border-gray-700">
                    <th  scope="col" class="sm:px-6 py-3  font-mono antialiased  ">
                        160kW
                    </th>
                </tr>
                <tr class=" border-b  border-gray-700">
                    <th  scope="col" class="sm:px-6 py-3  font-mono antialiased  ">
                        180kW
                    </th>
                </tr>
                <tr class=" border-b  border-gray-700">
                    <th  scope="col" class="sm:px-6 py-3  font-mono antialiased  ">
                        240kW
                    </th>
                </tr>
                <tr class=" border-b  border-gray-700">
                    <th  scope="col" class="sm:px-6 py-3  font-mono antialiased  ">
                        320kW
                    </th>
                </tr>
                <tr class=" border-b  border-gray-700">
                    <th  scope="col" class="sm:px-6 py-3  font-mono antialiased  ">
                        400kW
                    </th>
                </tr>
                
     
            </tbody>
      </table>
      <div className='h-[300px] w-[300px] my-auto  lg:ml-0 mr-auto ml-auto'>
        <img src="/44.png" alt=""  className='w-full h-full'/>
      </div>
    
      </div>
    </div>
    
        
    
    </div>
  )
}

export default MobilityTable