
import Hero from '../components/Home/Hero'
import Analytics from '../components/Home/Analytics'
import References from '../components/Home/References'
import  {motion} from "framer-motion"

export default function Home({content}) {
  return (
    <motion.div
    initial={{ opacity: 0}}
    animate={{ opacity: 1}}
    transition={{duration: 0.3}}
    className='w-screen  '>
       
            
         <Hero content={content}/>
         
         <References content={content}/>


      

    </motion.div>
  )
}
