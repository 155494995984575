import './App.css';
import { useState } from "react";

import Navbar from './components/Navbar';
import Home from './pages/Home';
import References from './components/References';
import Contact from './pages/Contact';
import EnerjiDepolama from './pages/FaaliyetAlanları/EnerjiDepolama';
import Mobility from './pages/FaaliyetAlanları/Mobility';
import Hakkimizda from './pages/Hakkimizda';
import Footer from './components/Footer';
import ElektrikMalzemeleri from './pages/FaaliyetAlanları/ElektrikMalzemeleri';
import EnerjiIzleme from './pages/Elektrik-Malzemeleri/EnerjiIzleme'
import OG from './pages/Elektrik-Malzemeleri/OG'
import AGPanolar from './pages/Elektrik-Malzemeleri/AGPanolar/AGPanolar'
import Transformatorler from './pages/Elektrik-Malzemeleri/Transformatorler/Transformatorler'
import Search from './pages/Search/Search';
import RTUPanoları from './pages/Elektrik-Malzemeleri/RTUPano/RTUPano'
import AkuRedresor from './pages/Elektrik-Malzemeleri/AkuRedresor/AkuRedresor'
import AGParafudr from './pages/Elektrik-Malzemeleri/AGParafudr/AGParafudr';
import SacBeton from './pages/Elektrik-Malzemeleri/SacBeton/SacBeton';
  
import Translation from './Data';
import {
  Routes,
  Route
} from "react-router-dom";
import { useEffect } from "react";



function App() {

  var defaultLanguage = 'english';

  const storedLanguage = localStorage.getItem('language');
  if(storedLanguage){
    defaultLanguage = storedLanguage
  } 
  const [language, setLanguage] = useState(defaultLanguage);
  const saveLanguageToLocalStorage = (selectedLanguage) => {
    localStorage.setItem('language', selectedLanguage);
  };


  const [content,setContent]=useState({})

  useEffect(()=>{
    
    if(language=="english"){
        setContent(Translation.english)
    }else if(language=="turkish"){
        setContent(Translation.turkish)
    }
})

  const [isOpenContantPage, setIsOpenContantPage] = useState(false);
  return (
    <div className="App  " id='App'>

      <div className="z-40 absolute  inline-block" >
  
        <Navbar setIsOpenContantPage={setIsOpenContantPage}
        saveLanguageToLocalStorage= {saveLanguageToLocalStorage}
        setLanguage={setLanguage}
        language={language}
        content={content}
        />
  </div>    

  {!isOpenContantPage && 
    <div className="z-1 relative inline-block h-full">
      <Routes >
      
      <Route path="/hakkimizda"   element={<Hakkimizda   content={content}/>} />
        <Route path="/e-mobility"   element={<Mobility  content={content}/>} />
        <Route path="/enerji-depolama"   element={<EnerjiDepolama content={content}/>} />
        <Route path="/elektrik-malzemeleri"   element={<ElektrikMalzemeleri content={content} setIsOpenContantPage={setIsOpenContantPage} />} />
        <Route path="/" element={<Home  content={content} />} />
        <Route path="/search/:searchkey" element={<Search  language={language}  content={content}/>} />
        <Route path="/search" element={<Search   language={language} content={content}/>} />
        <Route className="z-0 relative" path="/references" element={<References content={content} />} />
        <Route path="/elektrik-malzemeleri/og"   element={<OG setIsOpenContantPage={setIsOpenContantPage} content={content}/>} />
        <Route path="/elektrik-malzemeleri/enerji-izleme"   element={<EnerjiIzleme content={content} setIsOpenContantPage={setIsOpenContantPage}/>} />
        <Route path="/elektrik-malzemeleri/ag-panolar"   element={<AGPanolar content={content} setIsOpenContantPage={setIsOpenContantPage}/>} />
        <Route path="/elektrik-malzemeleri/rtu-panolar" element={<RTUPanoları content={content} setIsOpenContantPage={setIsOpenContantPage}/>} />
        <Route path="/elektrik-malzemeleri/transformatorler"   element={<Transformatorler content={content} setIsOpenContantPage={setIsOpenContantPage}/>} />
        <Route path="/elektrik-malzemeleri/aku-redresor"   element={<AkuRedresor content={content} setIsOpenContantPage={setIsOpenContantPage}/>} />
        <Route path="/elektrik-malzemeleri/ag-parafudr" element={<AGParafudr content={content} setIsOpenContantPage={setIsOpenContantPage}/>} />
        <Route path="/elektrik-malzemeleri/sac-beton-kosk" element={<SacBeton content={content} setIsOpenContantPage={setIsOpenContantPage}/>} />
      </Routes>

      </div>
}
      <div className="z-50 absolute inline-block h-full  ">
    {isOpenContantPage && <Contact   content={content} setIsOpenContantPage={setIsOpenContantPage} />}

  </div>
  {!isOpenContantPage &&  <Footer content={content} setIsOpenContantPage={setIsOpenContantPage}/>}
    </div>
  );
}

export default App;
