import React from 'react'
import MobilityTable from './MobilityTable'
const Mobility = ({content}) => {
  return (
    <div className='border border-green-400'>
      
    <div className='mt-[70px] w-screen pr-2.5   bg-white  top-0 	' >
    
      <div className=' pb-24   pt-8'>
        <div className='text-center text-3xl font-bold tracki w-full absolute ' >
        {content?.EMobil?.[0]}
        </div>
        <div className=' mx-auto  text-xl text-center text-gray-400 w-full  absolute mt-8' >

        {content?.EMobil?.[1]}</div>
      </div>
    
      <div>
      
      <section className="  container w-full mx-auto text-black lg:px-48">

	<div className="    p-0 grid justify-center gap-4  lg:grid-cols-2 xl:grid-cols-4">
		<div className="flex flex-col  px-8 py-6  ">
    <h2 className="mb-2 text-lg font-semibold sm:text-xl title-font text-black overflow-hidden  ">
        <img
                className="h-24     scale-150 lg:p-auto  absolute -mt-8 z-0    "
                src="/circleLogo.svg"
                alt=""
              />
        <span className='ml-12 z-2 relative'>{content?.EMobil?.[2]}</span></h2>
			<p className="flex-1 mb-4 text-base leadi text-gray-800 z-2 relative">{content?.EMobil?.[3]}</p>

		</div>
	
    <div className="flex flex-col px-8 py-6  ">
			<h2 className="mb-2 text-lg font-semibold sm:text-xl title-font text-black overflow-hidden  ">
        <img
                className="h-24     scale-150 lg:p-auto  absolute -mt-8   z-0   "
                src="/circleLogo.svg"
                alt=""
              />
        <span className='ml-12  z-2 relative'>{content?.EMobil?.[4]}</span></h2>
			<p className="flex-1 mb-4 text-base leadi text-gray-800  z-2 relative">{content?.EMobil?.[5]}</p>
	
		</div>
    <div className="flex flex-col px-8 py-6  ">
    <h2 className="mb-2 text-lg font-semibold sm:text-xl title-font text-black overflow-hidden  ">
        <img
                className="h-24     scale-150 lg:p-auto  absolute -mt-9  z-0    "
                src="/circleLogo.svg"
                alt=""
              />
        <span className='ml-12  z-2 relative'>{content?.EMobil?.[6]}</span></h2>
			<p className="flex-1 mb-4 text-base leadi text-gray-800  z-2 relative">{content?.EMobil?.[7]}</p>
	
		</div>
    <div className="flex flex-col px-8 py-6  ">
    <h2 className="mb-2 text-lg font-semibold sm:text-xl title-font text-black overflow-hidden  ">
        <img
                className="h-24     scale-150 lg:p-auto  absolute -mt-9   z-0   "
                src="/circleLogo.svg"
                alt=""
              />
        <span className='ml-12  z-2 relative'>{content?.EMobil?.[8]}</span></h2>
			<p className="flex-1 mb-4 text-base leadi text-gray-800  z-2 relative">{content?.EMobil?.[9]}</p>
	
		</div>
	
	</div>
  <div className='  m-0 p-0'>
        <img src="emobil.jpg" alt="" className='mx-auto ' />
      </div>
   <MobilityTable content={content}/>
</section>
      </div>
		</div>
    
    
    </div>
  )
}

export default Mobility