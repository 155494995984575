import { useEffect, useState } from 'react'
import { Dialog } from '@headlessui/react'
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline'
import { MdSearch } from "react-icons/md";
import OutsideClickHandler from 'react-outside-click-handler';
import About from './NavbarToolTips/About';
import Business from './NavbarToolTips/Business';
import { Link } from "react-router-dom";


import {IoMdArrowDropright , IoMdArrowDropdown} from "react-icons/io"
export default function Navbar({setIsOpenContantPage,saveLanguageToLocalStorage,setLanguage,language,content}) {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
  const [openFaliyetAlanları, setOpenFaliyetAlanları] = useState(false);
  const [openMobilFaliyetAlanları, setopenMobilFaliyetAlanları] = useState(false);
  const [openHakkimda, setOpenHakkimda] = useState(false);
  const [currentTooltipContent, setCurrentTooltipContent] = useState("");
  const [searchKey, setSearchKey] = useState("");
  const scrollReferences = () => {
    setTimeout(()=>{
      const element = document.getElementById('references');
      if (element) {
        setTimeout(() => {
        element.scrollIntoView({ behavior: 'smooth' });
      },1)
      }
    },1)
  };
  


  return (
    <div className='fixed min-w-full top-0 m-0  '>
    <div className=" fixed w-full  bg-white top-0 ">
      <header className="sticky inset-x-0 top-0 ">
        <nav className=" flex items-center justify-between  my-8 lg:my-4 px-8" aria-label="Global">
          <div className="flex lg:flex-1 ">
            <a href="#" className="  ">
              <span className="sr-only">Your Company</span>
              <Link to="/">
                <div className='overflow-hidden'>
              <img
                className="w-40  p-4  scale-150 lg:p-auto  absolute -mt-10   "
                src="/Untitled.svg"
                alt=""
              />
              </div>
              </Link>
            
            </a>
          </div>
          <div className="flex lg:hidden">
            <button
              type="button"
              className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
              onClick={() => setMobileMenuOpen(true)}
            >
              <span className="sr-only">Open main menu</span>
              <Bars3Icon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <div className="hidden lg:flex xl:gap-x-6 md:gap-x-2">

      
        <a  key="hakkimizda-large" href="#"  id='hakkimizda'
            className=" p-4 text-sm font-semibold leading-6 text-gray-900 items-center ml-3 h-full "
            
            onClick={() => {setOpenHakkimda(true)
                setCurrentTooltipContent("hakkimizda")
            }}>                {content?.Navbar?.titles?.[0]}
            </a>
              <a key="faaliyet-large" href="#" 
              
                onClick={() => {setOpenFaliyetAlanları(true)
                    setCurrentTooltipContent("faaliyet")
                }}
               className="  p-4 text-sm font-semibold leading-6 text-gray-900 items-center ml-3 h-full ">
                {content?.Navbar?.titles?.[1]}
              </a>
            <Link 
            onClick={()=>{scrollReferences()}}
            className='p-4 text-sm font-semibold leading-6 text-gray-900 items-center ml-3 h-full' to="/">
            <a key="bayilik-large" href="#" 
               className="   ">
                              {content?.Navbar?.titles?.[2]}

              </a>     
            </Link>

             
              <a key="iletisim-large" href="#"
              onClick={()=> setIsOpenContantPage(true)} 
               className=" p-4 text-sm font-semibold leading-6 text-gray-900 items-center ml-3 h-full ">
                              {content?.Navbar?.titles?.[3]}

              </a>
           

          </div>
          <div className="hidden lg:flex lg:flex-1 lg:justify-end">
           
           
           
          { language == "english" && <a 
            onClick={()=>{
              saveLanguageToLocalStorage("turkish");
              setLanguage("turkish")
            }}
            href="#" className="text-sm font-semibold leading-6 text-gray-900">

              TR <span aria-hidden="true"></span>
            </a>
}
            { language == "turkish" && <a 
            onClick={()=>{
              saveLanguageToLocalStorage("english");
              setLanguage("english")
            }}
            href="#" className="text-sm font-semibold leading-6 text-gray-900">

              EN <span aria-hidden="true"></span>
            </a>}

            <input type="text"  onChange={(event)=>{
              setSearchKey(event.target.value)
            }} className='max-w-[150px] ml-4  font-thin text-gray-700 text-xs pl-2 border border-gray-400 py-1' />
            <Link
             
            to={`/search/${searchKey}`}>
              
            <a href="#" 
             onClick={()=>{
              setTimeout(()=>{
                document.body.scrollTop = document.documentElement.scrollTop = 0;

              },1)
            }}
            >
              <MdSearch className='ml-1 mt-1'/> 
              <span aria-hidden="true"></span>
            </a>
            </Link>
          </div>




        </nav>
        <Dialog as="div" className="lg:hidden" open={mobileMenuOpen} onClose={setMobileMenuOpen}>
          <div className="fixed inset-0 " />
          <Dialog.Panel className="fixed z-50  inset-y-0 right-0  w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
            <div className="flex items-center justify-between relative z-10">
              <a href="#" className="-m-2.5 ">
                <span className="sr-only">Your Company</span>
                <img
                  className="h-40   scale-150 p-auto z-4 absolute -mt-20"
                  src="/logo.svg"
                  alt=""
                />
              </a>
              <button
                type="button"
                className="-m-2.5 rounded-md p-2.5 text-gray-700"
                onClick={() => setMobileMenuOpen(false)}
              >
                <span className="sr-only">Close menu</span>
                <XMarkIcon className="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
            <div className="mt-6 flow-root z-50 relative">
              <div className="-my-6 divide-y divide-gray-500/10">
                <div className="space-y-2 py-6">
                    <Link to="/hakkimizda">
                      
                   <a
                        onClick={()=>{
                          setMobileMenuOpen(false)
                        }}
                      key="Hakkımızda-mobil"
                      className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                    >
                      {content?.Navbar?.titles?.[0]}
                    </a>
                    </Link>
                    <a
                      onClick={()=>{ 
                        setopenMobilFaliyetAlanları(!openMobilFaliyetAlanları)
                      }}
                      key="faaliyet-alanları-mobil"
                      
                      className="cursor-pointer flex -mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                    >
                     <IoMdArrowDropdown className={` transition- duration-300 mt-1 ${!openMobilFaliyetAlanları ? '-rotate-90' : ''}`} />  {content?.Navbar?.titles?.[1]} 
                     

                    </a>  
                    <div  className={` transition-transform mt-1 ${!openMobilFaliyetAlanları ? 'hidden-element' : 'show'}`}>
                    <Link to="/e-mobility">
                    <a
                     onClick={()=>{
                      setMobileMenuOpen(false)
                      setTimeout(()=>{
                    document.body.scrollTop = document.documentElement.scrollTop = 0;

                      },1)
                    }}
                      key="e-mobility-mobil"
                      className="flex -mx-3 block rounded-lg pr-3 pl-10 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50 "
                    >
                     <IoMdArrowDropright className='mt-1 '/> {content?.FieldsOfOperation?.[0]} 
                     
                    </a> 
                    </Link>

                    </div>
                    
                    <div  className={` transition-transform mt-1 ${!openMobilFaliyetAlanları ? 'hidden-element' : 'show'}`}>
                    <Link to="/elektrik-malzemeleri">
                    <a
                         onClick={()=>{
                          setMobileMenuOpen(false)
                          setTimeout(()=>{
                        document.body.scrollTop = document.documentElement.scrollTop = 0;
    
                          },1)
                        }}
                      key="elektrik-mal-mobil-nav"
                      href="#"
                      className="flex -mx-3 block rounded-lg pr-3 pl-10 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50 "
                    >
                     <IoMdArrowDropright className='mt-1 '/> {content?.FieldsOfOperation?.[1]}
                     
                    </a> 
                    </Link>
                    </div>   <div  className={` transition-transform mt-1 ${!openMobilFaliyetAlanları ? 'hidden-element' : 'show'}`}>
                    <Link to="/enerji-depolama">
                    
                    <a
                    onClick={()=>{
                      setMobileMenuOpen(false)
                      setTimeout(()=>{
                    document.body.scrollTop = document.documentElement.scrollTop = 0;

                      },1)
                    }}
                      key="enerji-depolama-mobil-nav"
                      className="flex -mx-3 block rounded-lg pr-3 pl-10 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50 "
                    >
                     <IoMdArrowDropright className='mt-1 '/> {content?.FieldsOfOperation?.[2]}
                     
                    </a> </Link>
                    </div>
                    
                     <Link to="/" onClick={()=>{setMobileMenuOpen(false); scrollReferences()}}>  <a
                      onClick={()=>{}}
                      key="bayilik-ve-markalar-mobil"
                      href="#"
                      className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                    >
                     {content?.Navbar?.titles?.[2]}
                      
                    </a> </Link>    <a
                     onClick={()=> {setIsOpenContantPage(true) ;  setMobileMenuOpen(false)}} 
                      key="iletisim-mobil"
                      href="#"
                      className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                    >
                   {content?.Navbar?.titles?.[3]}
                    </a>
                </div>
                <div className="py-6">
                { language == "english" && 
          
                  <a
                    href="#"
                    className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                    onClick={()=>{
                      saveLanguageToLocalStorage("turkish");
                      setLanguage("turkish")
                    }}
                  >
                    TR
                  </a>
}
{ language == "turkish" && 
          
          <a
            href="#"
            className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
            onClick={()=>{
              saveLanguageToLocalStorage("english");
              setLanguage("english")
            }}
          >
            EN
          </a>
}
                  <Link to="/search">
                  <a     onClick={()=>{
                      setMobileMenuOpen(false)
                      setTimeout(()=>{
                    document.body.scrollTop = document.documentElement.scrollTop = 0;

                      },1)
                    }}
                    className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50 flex items-center mr-4">             
                           {content?.Navbar?.titles?.[4]}
                    <MdSearch className='ml-2'/></a>
                </Link>
                </div>
              </div>
            </div>
          </Dialog.Panel>
        </Dialog>
      </header>
      <div>
        

      <OutsideClickHandler
      onOutsideClick={() => {
        setOpenFaliyetAlanları(false)
        setOpenHakkimda(false)

      }}
    > <div  id="contentMask" 
    className={`w-full bg-black  transition-all	 duration-500 overflow-hidden	  ${
            openFaliyetAlanları || openHakkimda ? "max-h-[800px] " : "max-h-[0px] " }`
          }>
            <div id="content">
            {currentTooltipContent ==="hakkimizda" &&
     <About 
          content={content}
          setOpenHakkimda={setOpenHakkimda}/>
      } {currentTooltipContent ==="faaliyet" &&
      <Business 
      content={content}
      setOpenFaliyetAlanları={setOpenFaliyetAlanları}/>
       }
            </div>
    
    </div>
    </OutsideClickHandler>
   

 

    </div>
    
    
    </div>
 
            </div>
  )
}
