import React, { useState } from "react";
import "./Search.css"
import { Link } from "react-router-dom";
import {
  BrowserRouter as Router,

  useParams
} from "react-router-dom";
import  {motion} from "framer-motion"



const values= [
  {
    "keyword":"oghücreleroghucrelerMedium Voltage SwitchgearsGas Insulation (GIS) Modular and RMU Compact SwitchgearsPrimary, Single and Double BusbarSecondary, Single Busbar Air Insulation Modular SwitchgearMetal Clad (LSC2B) Withdrawable Switchgear",
    "link":"/elektrik-malzemeleri/og",
    turkish:{
      title:"OG Hücreler",
      description: "Orta Gerilim Hücreleri; enerji üretim, iletim ve dağıtım merkezleri, endüstriyel tesisler, yenilenebilir enerji santralleri, binalar, şehir içi hafif raylı ulaşım tesisleri (metro-tramvay) gibi orta gerilim enerjisine gereksinim duyulan tüm sektörlerde kullanılmaktadır."
    },
      english:{
        title:"Medium Voltage Switchgears",
      description: "Medium Voltage Switchgear; is used in all sectors requiring medium voltage power such as energy generation, transmission and distribution centers, industrial facilities, renewable energy power plants, buildings, urban light rail transport facilities (metro-tramway)."
      }
  },
  {
    "keyword":"Sheet Steel & Concreate KiosksSheet Steel Concreate KiosksSac & Beton KöşklerSaç & Beton KöşklerSac Beton KöşklerSaçBeton Köşkler",
    "link":"/elektrik-malzemeleri/og",
    turkish:{
      title:"Sac & Beton Köşkler",
      description: "Sac Köşkler enerji iletim, dağıtım, yenilenebilir enerji santralleri, petrol & gaz, endüstri, kompakt trafo merkezleri sektörlerinde kullanılmaktadır."
    },
      english:{
        title:"Sheet Steel & Concreate Kiosks",
      description: "Sheet Steel Substations are used in energy transmission, distribution, renewable energy power plants, oil & gas, industrial, and compact transformer substation sectors."
      }
  },  {
    "keyword":"TransformatorlerTransformers",
    "link":"/elektrik-malzemeleri/transformatorler",
    turkish:{
      title:"Transformatorler",
      description: "Transformatör, iki veya daha fazla devre arasındaki elektrik enerjisi aktarımını elektromanyetik indüksiyonla sağlayan bir sistemdir. "
    },
      english:{
        title:"Transformers",
      description: "A transformer is a system that enables the transfer of electrical energy between two or more circuits through electromagnetic induction."
      }
  }, {
    "keyword":"Alçak Gerilim PanolarLow Voltage Panels",
    "link":"/elektrik-malzemeleri/ag-panolar",
    turkish:{
      title:"Alçak Gerilim Panolar",
      description: "Alçak gerilim panosu, fabrika ve atölye gibi yerlerde elektrik enerjisinin ana dağıtımının yapıldığı panolardır. "
    },
      english:{
        title:"Low Voltage Panels",
      description: "Low voltage panel is a type of panel where the main distribution of electrical energy takes place in locations such as factories and workshops."
      }
  },{
    "keyword":"RTU PanolarıRTU 7M",
    "link":"/elektrik-malzemeleri/rtu-panolar",
    turkish:{
      title:"RTU Panolar",
      description: "İki farklı sistem arasında arayüz görevi üstlenen panolardır. Elektriksel sinyalleri Scada&Otomasyon sistemlerine taşımak için kullanılırlar."
    },
      english:{
        title:"RTU",
      description: "These are panels that undertake the interface task between two different systems. They are used to carry electrical signals to SCADA & Automation systems."
      }
  },{
    "keyword":"Akü-RedresörGruplarıBatteryChargersAküRedresör",
    "link":"/elektrik-malzemeleri/aku-redresor",
    turkish:{
      title:"Akü-Redresör Grupları",
      description: "Akü Redresör Grubu; enerji iletim üretim ve dağıtım firmaları, Jeotermal santraller, Rüzgar enerji santralleri, güneş enerji santralleri, trafo köşkleri, trafo merkezleri ve orta gerilim panolarının kesintisiz beslenmelerini sağlamak amacıyla tasarlanmış endüstriyel tip redresörlerdir."
    },
      english:{
        title:"Battery Chargers",
      description: "'Battery Rectifier System' is an industrial type rectifier designed to ensure uninterrupted power supply for energy transmission, generation, and distribution companies, geothermal power plants, wind energy plants, solar energy plants, transformer kiosks, transformer substations, and medium voltage panels."
      }
  },{
    "keyword":"LVSurgeArresterAlçak Gerilim Parafudr",
    "link":"/elektrik-malzemeleri/ag-parafudr",
    turkish:{
      title:"Alçak Gerilim Parafudr",
      description: "Parafudrlar, yıldırımdan dolayı meydana gelen aşırı gerilimlere karşı elektrikle çalışan cihazları korumak amacıyla üretilmektedir."
    },
      english:{
        title:"LV Surge Arrester",
      description: "Surge protectors are produced to protect electrically operated devices against overvoltages caused by lightning."
      }
  },{
    "keyword":"Measuring, Protecting, ControllingÖlçme, Koruma, Kumanda",
    "link":"/elektrik-malzemeleri/enerji-izleme",
    turkish:{
      title:"Ölçme, Koruma, Kumanda",
      description: "Şehir şebekesini sürekli izleyerek oluşabilecek herhangi bir gerilim yada frekans bozulmasında enerji santrali ile elektrik şebekesini belirlenen süre içerisinde ayırır ve sistem nominal değerine döndüğünde elektrik üretim tesisinin tekrar devreye girmesini sağlar."
    },
      english:{
        title:"Measuring, Protecting, Controlling",
      description: "By monitoring the city grid continuously, it separates the power plant and the electricity grid within the specified time in case of any voltage or frequency deterioration and ensures that the electricity generation facility is reactivated when the system returns to its nominal value."
      }
  },
  
  
  ]
  

  function bosluklariSil(str) {
    return str.replace(/\s+/g, '');
}

export default function Search({language,content}) {
    var { searchkey } = useParams();
    const [pageSearchContent, setPageSearchContent] = useState(searchkey);
  
    var results = [];
      if(searchkey){
      results = values.filter((item) =>
      bosluklariSil(item?.keyword?.toLowerCase()).includes(bosluklariSil(pageSearchContent.toLowerCase()))
    );}
    if(results.length === 8) results = []
    const [showedContent, setShowedContent] = useState(results);
    
  return (
    <motion.div 
    initial={{ translateY: "100%"}}
    animate={{ translateY: "0"}}
    transition={{duration: 0.3}}
    class="s130 w-screen">
      <form>
        <div class="inner-form">
          <div class="input-field first-wrap">
            <div class="svg-wrapper">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                <path d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z"></path>
              </svg>
            </div>
            <input  onChange={(event)=>{
              setPageSearchContent(event.target.value)
              var x = values.filter((item) => 
              bosluklariSil(item.keyword.toLowerCase()).includes(bosluklariSil(event.target.value.toLowerCase()))
            ) 
              setShowedContent(x)
              console.log()

            }} id="search" value={pageSearchContent} type="text" placeholder="" />
          </div>
          <div 
          class="input-field second-wrap">
            <button onClick={()=>{

            }} className="btn-search" type="button">{content?.Search?.[0]}</button>
          </div>
        </div>
        <span class="info">{content?.Search?.[1]}</span>
        {showedContent.map(item => (
       <div className="bg-gray-800 text-gray-100 my-2 rounded-3xl">
       <div className="container max-w-4xl px-10 py-6 mx-auto rounded-lg shadow-sm bg-gray-900">
         <div className="flex items-center justify-between">
         </div>
         <div className="mt-3">
          <Link to={item.link}>
           <a rel="noopener noreferrer" href="#" className="text-2xl font-bold hover:underline">{item?.[language]?.title}</a>
           </Link>
           <p className="mt-2">{item?.[language]?.description}</p>
         </div>
         <div className="flex items-center justify-between mt-4">
           <a rel="noopener noreferrer" href="#" className="hover:underline text-yellow-400">{content?.Search?.[2]}</a>
           <div>
         
           </div>
         </div>
       </div>
     </div>
        
      ))}
      </form>
    </motion.div>
  );
}