import React from 'react'

const References = ({content}) => {
    return (
        <div  className='w-full bg-white  container mx-auto pb-10'>
            <div id='references' className="p-12"></div>
            <div className='md:max-w-[1480px] m-auto max-w-[600px]  px-4 md:px-0 '>
            <h1 className='md:text-4xl sm:text-3xl text-2xl font-bold py-2 text-yellow-400 drop-shadow-[0_1.2px_1.2px_rgba(0,0,0,0.8)] text-center mb-4 '>{content?.Home?.Brands?.[0]}</h1>

                <h1 className='text-center text-xl font-bold text-gray-800'>{content?.Home?.Brands?.[1]}</h1>
                <div className='flex justify-center py-8 md:gap-8 '>
                    <div className='grid md:grid-cols-4 grid-cols-1 gap-2'>
                      <img className='mx-auto my-auto' src="/images/references/1.jpg" />
                      <img className='mx-auto my-auto' src="/images/references/2.jpg" />
                      <img className='mx-auto my-auto' src="/images/references/3.jpg" />
                      <img  className='mx-auto my-auto' src="/images/references/4.jpg" />
                      <img className='mx-auto my-auto' src="/images/references/5.png" />
                      <img className='mx-auto my-auto' src="/images/references/6.jpg" />
                      <img className='mx-auto my-auto' src="/images/references/7.png" />
                      <img className='mx-auto my-auto' src="/images/references/8.jpg" />
                      <img className='mx-auto my-auto' src="/images/references/9.jpg" />
                      <img className='mx-auto my-auto' src="/images/references/10.jpg" />
                      <img className='mx-auto my-auto' src="/images/references/11.png" />

                    </div>
                </div>
            
            </div>
    
        </div>
      )
}

export default References