import React from 'react'
import { Link } from "react-router-dom";

const About = ({setOpenHakkimda,content}) => {
    const scrollReferences = (id) => {
        setTimeout(()=>{
          const element = document.getElementById(id);
    
          if (element) {
            // 👇 Will scroll smoothly to the top of the next section
            setTimeout(() => {
            element.scrollIntoView({ behavior: 'smooth' });
            })
          }
        },1)
      };
  return (
    <div className='lg:block hidden '> <section className="bg-gray-800 text-gray-100">


	<div className="  container mx-auto    px-12 flex flex-col  ">

		<div className=" flex container   relative   mr-120 xl:mr-0" >
		<img src="/light.jpg" alt="Örnek Resim" width="500" height="" className='min-h-[100px] p-12' />

		<h2 className="text-3xl font-bold  flex-row   p-6   absolute bottom-4 left-4 text-gray-800 bg-yellow-400 ">{content?.WhoWeAreTitle} </h2> 
		<div className="flex flex-col w-full p-6   font-bold leadi after:relative ">
		<div className='  my-auto'>
		<Link to="/hakkimizda" 
		onClick={()=>{ setOpenHakkimda(false) }}
		>
		<div
			
			className='flex overflow-x-auto '>
			<p className="mt-2   whitespace-nowrap   transition-all duration-200 hover:text-gray-800 hover:bg-yellow-400 bg-yellow-400/70 xl:w-[400px] w-[200px] xl:text-xl text-base font-semibold cursor-pointer   after:transition-all after:duration-200 p-8 after:content['']  relative after:absolute after:bg-gray-800 after:h-[3px]  after:w-0 after:left-0 after:bottom-[10px]  after:hover:w-full  ">{content?.AboutPageTitles?.[0]} <span className='absolute  right-0 pr-8'>	&rarr;</span></p>
		</div>
		</Link>
		<Link to="/hakkimizda"
		onClick={()=>{ setOpenHakkimda(false) ; scrollReferences("vizyon")}}> 
		<div
			className='flex overflow-x-auto '>
		<p className="mt-2   whitespace-nowrap   transition-all duration-200 hover:text-gray-800 hover:bg-yellow-400 bg-yellow-400/70 xl:w-[400px] w-[200px] xl:text-xl text-base font-semibold cursor-pointer   after:transition-all after:duration-200 p-8 after:content['']  relative after:absolute after:bg-gray-800 after:h-[3px]  after:w-0 after:left-0 after:bottom-[10px]  after:hover:w-full  ">{content?.AboutPageTitles?.[1]}<span className='absolute  right-0 pr-8'>	&rarr;</span></p>
		</div>	
		</Link>
		<Link to="/hakkimizda"
		onClick={()=>{ setOpenHakkimda(false) ; scrollReferences("misyon")}}
		> 

		<div
			 
			className='flex overflow-x-auto '>
		<p className="mt-2   whitespace-nowrap   transition-all duration-200 hover:text-gray-800 hover:bg-yellow-400 bg-yellow-400/70 xl:w-[400px] w-[200px] xl:text-xl text-base font-semibold cursor-pointer   after:transition-all after:duration-200 p-8 after:content['']  relative after:absolute after:bg-gray-800 after:h-[3px]  after:w-0 after:left-0 after:bottom-[10px]  after:hover:w-full  ">{content?.AboutPageTitles?.[2]}<span className='absolute  right-0 pr-8'>	&rarr;</span></p>
		</div>
		</Link>

		</div>
	</div>
		</div>

		
	</div>
</section></div>
  )
}

export default About