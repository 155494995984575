
module.exports = {
    "english":{
        "WhoWeAreTitle":"About Us",
        "AboutPageTitles":["Who We Are","Vision","Mission"],
        "aboutPage":{
            "titles":["x","y"]
        },
        "AboutPageDescriptions":[
            "We are engineers who share our 23 years experience of LV&MV project design, application and marketing in the field of electrical engineering with the sector, devote their hearts to innovative, high quality, sustainable technologies, believe that a better world is possible with our human-oriented working method and spend all their knowledge in this direction."
            ,"To grow together with our customers with a positive, innovative value-adding approach focused on the needs and wishes of our customers.",
            "To be customer-oriented with our human-oriented, innovative vision that respects ethical values, realizes the necessary actions and projects for a sustainable environment in the region and in the world."
        ],
        Navbar: {
            titles: ["About Us"," Fields of Operation","Franchise and Brands","Contact","Search"]
        },
        Home: {
            HeroTitles: [" We are wherever there is energy.","DE Enerji Danışmanlık Ltd. Şti.","Think about your future, take care of your energy.","We provide end-to-end smart solutions to our customers in the processes of energy generation, transmission, distribution, and consumption.","Discover"],
            Brands: [
                "Fields of Operation",
                "The brands and franchises we proudly present always prioritize customer satisfaction."
            ]
        },
       
                EMobil: [
                    "E-Mobility",
                    "Why Electric Car?",
                    "Environmentally Friendly",
                    "Electric vehicles are environmentally friendly and do not emit carbon emissions due to exhaust gas. They do not harm the environment through fuel consumption.",
                    "Clean",
                    "Due to the absence of exhaust, they do not contribute to noise pollution.",
                    "Lower Maintenance Needs",
                    "Electric vehicles have a simpler motor structure as they directly derive power from the battery. Therefore, they require lower maintenance compared to fossil fuel vehicles.",
                    "Economic Opportunity",
                    "For countries like ours that import fossil fuels and incur a trade deficit as a result, electric vehicles present an economic opportunity.",
                    "AC Vehicle Charging Stations",
                    "As power increases during the charging process, the required charging time to fill the battery inside the vehicle decreases. Additionally, the vehicle's charging capacity determines the charging time.",
                    "Based on Power Types;",
                    "Single Phase",
                    "Home Type",
                    "1 Outlet",
                    "Commercial Type",
                    "Home Type",
                    "2 Outlets",
                    "Three Phase",
                    "Commercial Type",
                    "DC Vehicle Charging Stations",
                    "In our dual socket products, we divide the power using DC bars and contactors. For example, in a 120kW station, a single vehicle can charge at 120kW from either the 1st or 2nd socket. When two vehicles are charging simultaneously, power sharing will be 60kW for the 1st socket and 60kW for the 2nd socket.",
                    "Based on Power Types;",
                    "Single Socket",
                    "Single Socket / Dual Socket"
                ],
        FieldsOfOperation: [
            "E-Mobility",
            "Electrical Material",
            "Energy Storage"
        ],
        ContactForm: [
            "Contact",
            "Name",
            "Email",
            "Message",
            "Your name",
            "Your Email",
            "Message...",
            "Form",
            "Send",
            "Company",
            "Your Company"
        ],
        ElectricalMaterial: [
            "Medium Voltage Switchgears",
            "Sheet Steel & Concreate Kiosks",
            "Transformers",
            "Low Voltage Panels",
            "RTU ",
            "Battery Chargers",
            "LV Surge Arrester",
            "Measuring, Protecting, Controlling",
            "Get  Offer",
            "Get Offer Now",
            "Contact"

        ],
        EnergyStore: [
            "Energy Storage",
            "Powerful and Sustainable Energy!",
            "Adapting to Increasing Demand",
            "The purpose of energy storage is to minimize the difference that may arise from the ratio between energy supply and demand. In this way, the efficiency of the energy system used increases and energy savings are provided.",
            "Due to reasons such as the increase in the share of renewable energy sources in energy production, the increase in the share of electric vehicles in the system and the variability of consumption demands in time, it has become essential to store the electricity produced in the areas where the energy is produced and to give it to the system when needed.",
            "It is important for energy production and transmission to be sustainable and efficient.",
            "Renewable Energy and Storage Solutions",
            "In the residential energy generation model, there are energy storage systems with powers of 5kW, 10kW, 15kW, 20kW. In addition, these systems can be energy storage systems installed in city centers by electricity administrations and/or energy sales companies independent of commercial users.",
            "Solutions Tailored to Plant Capacity",
            "These are the energy storage systems established in accordance with the capacity of the relevant power plant within the power plants."
        ],
        OGContents: [
            "Medium Voltage Switchgears",
            "Up to 40,5kV 2500A 31,5kA AIS, GIS, Metal Clad (LSC2B), GIS and SF6 Free MV switchgear",
            "a.	Gas Insulation (GIS) Modular and RMU Compact Switchgears",
            "Up to 40,5kV 2500A 31,5kA single and double bus-bar switchgear",
            "Primary, Single and Double Busbar ",
            "Technical Features ",
            "Rated Voltage (kV) : ",
            "Rated Current (A): ",
            "Short-Circuit Breaking (kA)",
            "Secondary, Single Busbar",
            "b. Air Insulation Modular Switchgear",
            "Up to 36kV 1250A 25kA ",
            "c.	Metal Clad (LSC2B) Withdrawable Switchgear",
            "Up to 36kV 2500A 40kA",
        ],
        Transformers:[
            "Transformers",
            "Hermetic Type Distribution Transformers",
            "Technical Features",
            "Up to 36kV Voltage Level",
            "25 - 5000 kVA power range",
            "Power Transformers",
            "within the power range of 5MVA-250MVA"
        ],
        AGPanel:[
            "Low Voltage Panels",
            "Solar PP, Special and TEDAS type LV panels",
            "Smart Low Voltage Panels",
        ],
        AGPanelFeatures:[
           "Real time control:",
           "Current, voltage, power, kVar monitoring",
           "Loss and leakage monitoring",
           "Unbalanced load control",
           "Blown fuse check",
           "Control of charging curves (electric vehicles)",
           "Earth leakage detection",
           "Saturation of LV lines",
           "Overvoltage and undervoltage control ",
        ],
        RTUPano:[
            "RTU ",
            'These are the boards that are RTU "Remote Terminal Unit" and act as an interface between two different systems. They are used to carry electrical signals to SCADA and automation systems.',
            "It can be design according to customer needs.",
            "Communication Protocols",
            "Modbus master / slave (RTU / ASCII / TCP)",
            "M-bus master (serial/CTP)",
            "IEC 60870-5-101 master/slave",
            "IEC 60870-5-103 master",
            "IEC 60870-5-104 master/slave",
            "DNP3 master/slave",
            "IEC 61850",
            "DLMS",
            "Transparent tunnel from TCP to serial",
            "MQTT or restful API as optional",
            "Features",
            "1xRS485/232; 1xRS485; 2xEthernet",
            "1x Digital Input",
            "WIFI, 2G/3G/4G(LTE) integrated communication",
            "2 SIM card modem",
            "PLC logic",
            "Internal memory: SD card",
            "RTC Clock and NTP server/client",
            "Hardware watchdog",
            "Alarm management",
            "User management",
            "Cyber security according to industry standards",
            "Syslog event to remote server",
            "SOE event management",
            "Web-based configuration",
            "Detailed information on connected devices",
            "Network traffic monitoring and filtering",
            "Plug&Play configuration for I/O modules",
            "Remote Authentication Dial-In User Service (RADIUS)",
            "Modular (16 Module, 8 DI-8DO Module, 8 unit 4-20mA analog input module)",
         ],
         TrafoMerkezi:[
            "Sheet Steel & Concreate Kiosks",
            "Monoblok Concreate Transformer Substation",
            "Type tested, fully equipped transformer substations up to 36kV 630A 25kA, in different powers and with different MV cubicle types (AIS, GIS, RMU, SF6 Free) in accordance with TEDAŞ specifications or compatible with special project",
            "Technical Features",
            "Rated Volatage (kV)",
            "Max. Transformer Power ",
            "Internal Arc (kA)",
            "Switchgear Type",
            "Sheet Steel Transformer Substation",
            "Sheet steel substations up to 40.5kV 630A 25kA level, with different powers and with different MV cubicle types (GIS, RMU, SF6 Free), which we design according to your needs. ",
            "Smart Grid Substation",
            "Smart grid substation equipped with remote monitoring, measurement and control. RTU panel, smart transformer with voltage tap changer, LV panels where I, V, kW, KVar, cosø measurements of input and each feeder output are made, and remote controllable MV cubicles are the main elements of the system.",
        ],
        AkuRedresor:[
            "Battery Chargers",
            "In medium voltage switching facilities, Scada devices is a DC supply system used to meet the DC needs of measurement, protection and medium voltage switching equipment.",
            "TEDAŞ type",
            "Complies with TEDAŞ-MLZ/2018-065.A specification",
            "Easy-to-Use Interface",
            "Dry Contact Outputs for SCADA Communication + Specified signals can be monitored with Graphic LCD screen or LEDs and also transferred to SCADA. Optional RS 485 serial communication port is available.",
            "20 Event Records (10 General Events + 10 Battery Events)",
            "SCR Based Microprocessor + Large Graphic LCD Control Panel",
            "Low Ripple Voltage",
            "Automatic Battery Maintenance",
            "Stable Output Voltage",
            "Auto / Manual Fast Charge Modes",
            "Use of Quality Materials for Long Years of Use",
            "TEIAS Type",
            "It complies with TEİAŞ specification.",
            "Technicial Features",
            "Modbus master / slave(RTU/ASCII/TCP)",
            "Control Unit with SCR Based Microprocessor and Large Graphic LCD",
            "Low Ripple Voltage",
            "Automatic Battery Maintenance",
            "Closest Ideal Battery Life",
            "IP 55 Protection Class",
            "Fully Stable Output Voltage",
            "SCADA Compatible (RS485 – Modbus RTU)",
            "Possibility to Increase Power Up to 150Ah Battery Capacity (without any extra cost except for the battery)",
            "3x380Vac Input - 48Vdc Output",
            "Three-Phase Rectifier with 40A Charge Current",
            "Scada Contact Output",
            "Has Battery Control Module",
            "3x380Vac Input - 110Vdc Output",
            "Three Phase Rectifier with 30A Charge Current",
            "Scada Contact Output",
            "Has Battery Control Module",
            "EQUIPMENT",
            "Feed Input	",
            "Rated Output Voltage (DC)",
	        "27,1 V DC (in fully charged state)",
            "Battery Ah Capacity",
            "7Ah / 12Ah /18 Ah",
            "Voltage Regulation	",
            "± 1% at ± 20% input voltage change",
            "Dielectric Withstand Voltage	",
            "2 kV",
            "Start",
            "Start Softstart (Ramp)",
            "Charge Voltage",
            "2,25 VDC x number of rock",
            "Charging Current",
            "2A",
            "Input Power",
            "50VA / 36W	",
            "Charge Circuit Power",
            "48VA",
            "Output Power (7Ah)",
            "7A x 27,1V = 189,7VA (At Full Charge)",
            "Output Power (12Ah)",
            "12A x 27,1V = 325,2VA (At Full Charge)",
            "Output Power (18Ah)",
            "18A x 27,1V = 487,8VA (At Full Charge)",
            "Operating temperature",
            "Weight",
        ],
        AGParafudr: [
            "Low Voltage Surge Arrester",
            "It complies with TEDAŞ-MLZ-2003-006.C and TEDAŞ-MLZ-2022-077 specifications.",
            "Superior protection is provided by hybrid technology where varistors and spark gap are used together.",
            "Thanks to its compact design, it can be used comfortably even in the narrowest panels.",
            "Fault status can be monitored with the indicators on it.",
            "With the dry contact output, the fault status can be monitored remotely.",
            "Domestic design, domestic production",
            "5 years warranty",
            "TÜV certified",
            "According to EN 61643-11 standard, LV Surge arrester class is T1+T2.",
            "Electrical Characteristics",
            "Rated Voltage (AC) (50/60HZ)",
            "Highest Continuous operating AC Voltage (L-N)",
            "Rated Discharge Current (8/20µs)",
            "Peak Discharge Current (8/20µs)",
            "Pulse Discharge Current (10/350µs)",
            "Voltage Protection Level",
            "Reaction time",
            "Highest fuse",
            "Transient Overvoltage -120 min (l-N)",
            "Transient Overvoltage -200ms (N-PE)	",
            "Follow Current Limitation (N-PE)",
        ],
        Footer: [
            "Contact US",
            "We offer end-to-end smart solutions to our customers in the generation, transmission, distribution and consumption processes of energy.",
            "Institutional",
            "About Us",
            "E-Mobility",
            "Electrical Material",
            "Energy Storage",
            "Contact"
        ],
        EnergyMonitoring: [
            "Measuring, Protecting, Controlling",
            "Voltage and Frequency Protection Relay (Loss of Main /LOM)   ",
            "By monitoring the city grid continuously, it separates the power plant and the electricity grid within the specified time in case of any voltage or frequency deterioration and ensures that the electricity generation facility is reactivated when the system returns to its nominal value.",
            "PROTECTION FUNCTION",
            "Inverse Time Phase overvoltage",
            "Inverse Time Phase undervoltage",
            "Low frequency",
            "Over frequency",
            "Reclosing",
            "Frequency Change Rate (ROCOF)",
            "EQUIPMENT",
            "Feed Input ",
            "18-36V DC",
            "Digital Inputs",
            "4 Digital Input",
            "Digital Outputs ",
            "2 Digital Output",
            "Voltage Input  ",
            "4 Voltage Input",
            "Communication",
            "RS485 Communication Port",
            "Circuit Breaker Position Information (Input-1)",
            "Remote Active/Passive (Input-2)",
            "Remote Trip Relay (Input-3)",
            "Remote Close Relay (Input-4)",
            "Service Port",
            "Front Panel 'Set', 'Up' and 'Down' Buttons",
            "Adjustable Protection Functions",
            "Adjustable Number and Duration of Reclosing",
            "Modbus RTU Communication Protocol",
            "Exit Test Menu",
            "Event Log and Message Display",
            "Adjustable Time/Date",
            "PROTECTION FUNCTIONS",
            "Inverse Time Phase overvoltage (Busbar)",
            "Inverse Time Phase overvoltage (Line)",
            "Inverse Time neutral overvoltage (Busbar)",
            "Phase Balance voltage protection (Busbar)",
            "Inverse Time Phase undervoltage (Busbar)",
            "Inverse Time Phase undervoltage (Line)",
            "Inverse Time Positive sequence undervoltage (Busbar)",
            "Voltage change rate",
            "Under/Over frequency",
            "Frequency Change Rate (ROCOF)",
            "Vector Shift",
            "Overcurrent",
            "Reclosing",
            "Circuit Breaker monitoring",
            "Synchronous control",
            "Trip Circuit Control",
            "Closing Circuit Supervision",
            "External Trip",
            "Trip lockout",
            "EQUIPMENT",
            "Feed Input",
            "24-230 VDC/VAC",
            "Digital Inputs ",
            "11 Digital Input",
            "Digital Outputs ",
            "5 Digital Output",
            "Voltage Input  ",
            "5 Voltage Input",
            "Communication ",
            "RS485,Mikro USB, Ethernet* (Optional)",
            "Features"
        ],
        Search: [
            "Search",
            "Ex. Medium Voltage Switchgears, Transformers, RTU",
            "More "
        ]
    },
    "turkish":{
        "WhoWeAreTitle":"Hakkımızda",
        "AboutPageTitles":["Biz Kimiz","Vizyon","Misyon"],
        "aboutPage":{
            "titles":["xqweqe","yqwewq"]
        },
        "AboutPageDescriptions":[
            "Elektrik mühendisliği alanındaki 23 yıllık AG&OG projelendirme, dizayn, uygulama ve pazarlama tecrübemizi sektörle paylaşan, yenilikçi, kaliteli, sürdürebilir teknolojilere gönül vermiş, insan odaklı çalışma yöntemimiz ile ve daha iyi bir dünyanın işini iyi yapmaktan geçtiğine inan ve tüm bilgi birikimi bu yönde harcayan mühendisleriz. "
            ,"Müşterilerimizin ihtiyaç ve isteklerine odaklı, pozitif, yenilikçi değer katan yaklaşım ile müşterilerimizle beraber büyümek.",
            "Etik değerlere saygılı, bölgesinde ve dünyada sürdürülebilir bir çevre için gerekli eylemleri ve projeleri gerçekleştiren, insan odaklı, yenilikçi vizyonumuz ile müşteri odaklı olmak. "
        ],
        Navbar: {
            titles: ["Hakkımızda","Faaliyet Alanları","Bayilik Ve Markalar","İletişim","Arama"]
        },
        Home: {
            HeroTitles: ["Enerjinin olduğu her yerdeyiz","DE Enerji Danışmanlık Ltd. Şti.","Geleceğini düşün, enerjine sahip çık","Enerjinin üretim, iletim, dağıtım ve tüketim süreçlerinde müşterilerimize uçtan uca akıllı çözümler sunuyoruz","Keşfet"],
            Brands: [
                "Bayilik ve Markalar",
                "Gururla sunduğumuz markalar ve bayilikler, müşteri memnuniyetini her zaman ön planda tutuyor."
            ]
        },
        FieldsOfOperation: [
            "E-Mobility",
            "Elektrik Malzemesi",
            "Enerji Depolama"
        ],
        ContactForm: [
            "İletişim",
            "İsim",
            "Email",
            "Mesaj",
            "İsminiz",
            "Emailinz",
            "Mesajınız...",
            "Formu",
            "Gönder",
            "Firma",
            "Firmanız"
        ],
        ElectricalMaterial: [
            "Orta Gerilim Hücreler",
            "Sac & Beton Köşkler",
            "Transformatörler",
            "Alçak Gerilim Panolar",
            "RTU Panoları",
            "Akü-Redresör Grupları",
            "AG Parafudr",
            "Ölçme, Koruma, Kumanda",
            "Teklif Alın",
            "Hemen Teklif Al",
            "İletişim"
        ],
        EMobil:[
            "E-Mobility",
            "Neden Elektrikli Araba?",
            "Çevre Dostu",
            "Elektrikli araçlar çevre dostudur, egzoz gazı emisyonuna bağlı karbon salınımı yapmazlar. Yakıt tüketiminden kaynaklı çevreye zarar vermezler.",
            "Temiz",
            "Egzoz bulunmaması nedeniyle gürültü kirliliğini yapmazlar.",
            "Daha Düşük Bakım Gereksinimi",
            "Elektrikli araçlar, doğrudan bataryadan güç aldığı için daha basit bir motor yapısına sahiptir. Bu nedenle de fosil yakıtlı araçlara göre daha düşük bakım gerektirirler.",
            "Ekonomik Fırsat",
            "Ülkemiz gibi fosil kayıtları ithal eden ve bunun içinde cari açık veren ülkeler içinde ekonomik olarak fırsattır.",
            "AC Araç Şarj İstasyonları",
            "Şarj işleminde güç arttıkça, araç içerisinde yer alan bataryayı doldurmakiçin gerekli şarj süresi azalmaktadır.Ayrıca şarj süresini araç şarj kapasitesi de belirlemektedir",
            "Güç Tiplerine Göre;",
            "Monofaze",
            "Ev Tipi",
            "1 Çıkışlı",
            "İşletme Tipi",
            "Ev Tipi",
            "2 Çıkışlı",
            "Trifaze",
            "İşletme Tipi",
            "DC Araç Şarj İstasyonları",
            "Çift soketli ürünlerimizde DC bara ve kontaktörler vasıtasıyla güç bölümü yapmaktayız.Örneğin 120kW bir istasyonda; Tek araç şarj işleminde 1. veya 2. Soketten 120kW gücünde şarj yapılabilir. İki araç aynı anda şarj olduğunda ise 60kW 1. Soket ve 60kW 2. Soket güç paylaşımı olacaktır.",
            "Güç Tiplerine Göre;",
            "Tek Soketkli",
            "Tek Soketli / Çift Soketli",
        ],
        EnergyStore: [
            "Enerji Depolama",
            "Güçlü ve Sürdürülebilir Enerji!",
            "Artan Talebe Uyum",
            "Enerji depolamanın amacı enerji temini ve talebi arasındaki orantıdan doğabilecek farkı en aza indirmektir. Bu sayede kullanılan enerji sisteminin verimi artmakta ve enerji tasarrufu sağlanmaktadır.",
            "Yenilenebilir enerji kaynaklarının enerji üretimindeki payının artması, elektrikli araçların sistemdeki payının artması ve tüketim taleplerinin zaman dilimindeki değişkenliği gibi nedenlerle üretilen elektrik enerjinin üretildiği alanlarda depolanması ve ihtiyaç olunan zaman dilimlerinde sisteme verilmesi için zaruri hale gelmeye başlamıştır.",
            "Enerji üretim ve iletimin sürdürülebilir, verimli olması için önem arz etmektedir.",
            "Yenilenebilir Enerji ve Depolama Çözümleri",
            "Konut tipi enerji üretim modelinde 5kW, 10kW, 15kW, 20kW güçlerinde enerji depolama sistemleri bulunmaktadır. Ayrıca bu sistemler ticari kullanıcılardan bağımsız elektrik idarelerinin ve/veya enerji satış firmalarının şehir merkezlerine kurduğu enerji depolama sistemleri olabilmektedir.",
            "Santral Kapasitesine Uygun Çözümler",
            "Enerji santralleri bünyesinde ilgili enerji santralinin kapasitesine uygun kurulan enerji depolama sistemleridir."
        ],
        OGContents: [
            "Orta Gerilim Hücreler",
            "40,5kV Gerilim Seviyesi, 2500A 31,5kA’e kadar AIS, GIS, RMU, Metal Clad (LSC2B) ve SF6 Free OG hücre tedariki",
            "a. Gaz Yalıtımlı (GIS) Modüler ve RMU Kompakt Hücreler",
            "40,5kV Gerilim Seviyesi, 2000A 31,5kA’e kadar tek ve çift baralı hücreler",
            "Primer, Tek ve Çift Bara",
            "Teknik Özellikler",
            "Anma Gerilimi (kV) : ",
            "Anma Akımı (A): ",
            "Kısa Devre Kesme Kapasitesi (kA):",
            "Sekonder , Tek Bara",
            "b. Hava Yalıtımlı(AIS) Modüler Hücreler",
            "36kV Gerilim Seviyesi, 1250A 25kA’e kadar ",
            "c. Metal Clad (LSC2B) Arabalı Hücreler",
            "36kV Gerilim Seviyesi, 2500A 40kA’e kadar",
        ],
        Transformers:[
            "Transformatorler",
            "Hermetik Tip Dağıtım Transformatörleri",
            "Teknik Özellikler",
            "36kV Gerilim Seviyesine kadar",
            "25 - 5000 kVA Güç aralığında",
            "Güç Transformatörleri",
            "5MVA-250MVA Güç aralığında"
        ],
        AGPanel:[
            "Alçak Gerilim Panolar",
            "GES tipi, lokal, özel ve TEDAŞ tipi panolar",
            "Akıllı Alçak Gerilim Panolar",
        ],
        AGPanelFeatures:[
            "Gerçek zamanlı yönetim",
            "Akım, gerilim, güç, kVar izleme",
            "Kayıp ve kaçak izleme",
            "Dengesiz yük kontrolü",
            "Atmış sigorta kontrolü",
            "Şarj eğrilerinin kontrolü (elektrikli araçlar)",
            "Toprak kaçağı tespiti",
            "AG hatlarının doygunluğu",
            "Aşırı gerilim ve düşük gerilim kontrolü",
        ],
        RTUPano:[
            "RTU Panolar",
            'RTU "Remote Terminal Unit" iki farklı sistem arasında arayüz görevi üstlenen panolardır. Elektriksel sinyalleri SCADA ve otomasyon sistemlerine taşımak için kullanılırlar.',
            "İhtiyaca ve sistem gereksinimlerine göre dizayn edilirler.",
            "Haberleşme Protokolleri",
            "Modbus master / slave (RTU / ASCII / TCP)",
            "M-bus master (serial/CTP)",
            "IEC 60870-5-101 master/slave",
            "IEC 60870-5-103 master",
            "IEC 60870-5-104 master/slave",
            "DNP3 master/slave",
            "IEC 61850",
            "DLMS",
            "Transparrent tunnel from TCP to serial",
            "MQTT or restfull API as optional",
            "Özellikler",
            "1xRS485/232; 1xRS485; 2xEthernet",
            "1x Dijital giriş",
            "WIFI, 2G/3G/4G(LTE) entegre iletişim",
            "2 SIM kart modem",
            "PLC logic",
            "Dahili bellek: SD kart",
            "RTC Clock and NTP server/client",
            "Hardware watchdog",
            "Alarm yönetimi",
            "Kullanıcı(operatör) yönetimi",
            "Endüstri standartlarına uygun siber güvenlik",
            "Uzak sunucuya sistem günlüğü olayı",
            "Olay yönetimi, SOE",
            "Web tabanlı yapılandırma",
            "Bağlı cihazlar hakkında ayrıntılı bilgi",
            "Ağ trafiğini izleme ve filtreleme",
            "Tak ve çalıştır I/O modüller",
            "Remote Authentication Dial-In User Service(RADIUS)",
            "Modüler (16’lı Modulü, 8 DI-8DO Modulü, 8’li 4-20mA analog input modülü)",


        ],
        TrafoMerkezi:[
            "Sac & Beton Köşkler",
            "Monoblok Beton Trafo Merkezleri",
            "36kV 630A 25kA seviyesine kadar, farklı güçlerde ve farklı OG hücre tipleri ile (AIS, GIS, RMU, SF6 Free) TEDAŞ şartnamelerine uygun veya özel projelere uyumlu, tip testli, komple donanımlı trafo merkezleri",
            "Teknik Özellikler",
            "Anma Gerilimi (kV)	",
            "Maksimum Trafo Gücü",
            "İç Ark Dayanımı (kA)",
            "Hücre Tip",
            "Sac Köşkler",
            "40,5kV 630A 25kA seviyesine kadar, farklı güçlerde ve farklı OG hücre tipleri ile (GIS, RMU, SF6 Free) ihtiyacınıza göre tasarım yaptığımız sac trafo merkezleri",
            "Akıllı Trafo Merkezleri",
            "Uzaktan izleme, ölçme ve kontrol ile donatılmış akıllı trafo merkezi. RTU panosu, gerilim kademe değiştiricili transformatör, giriş ve her fider çıkışın I, V, kW, KVar, cosø ölçümlerinin yapıldığı AG panoları ve uzaktan kumanda edilebilir OG hücreler sistemin ana elemanlarıdır",
        ],
        AkuRedresor:[
            "Akü-Redresör Grupları",
            "OG Anahtarlama Tesislerinde, Scada Cihazları, ölçme, koruma ve OG anahtarlama ekipmanlarının DC ihtiyacını karşılamak amacı ile kullanılan DC besleme sistemidir.",
            "TEDAŞ Tipi",
            "TEDAŞ-MLZ/2018-065.A şartnamesine uygundur.",
            "Kolay Kullanımlı Arayüz",
            "SCADA Haberleşmesi İçin Kuru Kontak Çıkışları + Belirtilen sinyaller Grafik LCD ekran veya LED’ler ile takip edilebilir aynı zamanda SCADA’ya aktarılabilir. Opsiyonel olarak RS 485 seri haberleşme portu mevcuttur.",
            "20 Adet Olay Kaydı (10 Adet Genel Olay + 10 Adet Akü Olay)",
            "SCR Tabanlı Mikroişlemci + Geniş Grafik LCD Kontrol Paneli",
            "Düşük Dalgalanma (Ripple) Gerilimi",
            "Otomatik Akü Bakımı",
            "Stabil Çıkış Gerilimi",
            "Otomatik / Manuel Hızlı Şarj Modları",
            "Uzun Seneler Kullanım İçin Kaliteli Malzeme Kullanımı",
            "TEİAŞ Tipi",
            "TEİAŞ şartnamesine uygundur.",
            "Teknik Özellikler",
            "Modbus master / slave(RTU/ASCII/TCP)",
            "SCR Tabanlı Mikroişlemcili Geniş Grafik LCD’li Kontrol Ünitesi",
            "Düşük Dalgalılık (Ripple) Gerilimi",
            "Otomatik Akü Bakımı",
            "İdeale En Yakın Akü Ömrü",
            "IP 55 Koruma Sınıfı",
            "Tam Stabil Çıkış Gerilimi",
            "SCADA Uyumlu (RS485 – Modbus RTU)",
            "150Ah Akü Kapasitesine Kadar Güç Arttırma Olanağı (Akü haricinde herhangi bir ekstra masraf olmadan)",
            "3x380Vac Giriş - 48Vdc Çıkış",
            "40A Şarj Akımlı Trifaze Redresör",
            "Scada Kontak Çıkışlı",
            "Akü Denetim Modülüne Sahiptir",
            "3x380Vac Giriş - 110Vdc Çıkış",
            "30A Şarj Akımlı Trifaze Redresör",
            "Scada Kontak Çıkışlı",
            "Akü Denetim Modülüne Sahiptir",
            "DONANIM",
            "Besleme Girişi	",
            "Anma Çıkış Gerilimi (DC)",
	        "27,1 V DC (tam şarjlı durumda)",
            "Akü Ah Kapasitesi",
            "7Ah / 12Ah /18 Ah",
            "Gerilim Regülasyonu	",
            "± %20 giriş gerilimi değişikliğinde ± %1",
            "Dielektrik Dayanım Gerilimi	",
            "2 kV",
            "Start",
            "Start Softstart (Ramp)",
            "Şarj Gerilimi	",
            "2,25 VDC x Göz Sayısı",
            "Şarj Akımı",
            "2A",
            "Giriş Gücü",
            "50VA / 36W",
            "Şarj Devresi Gücü",
            "48VA",
            "Çıkış Gücü (7Ah)",
            "7A x 27,1V = 189,7VA (Tam Şarjda)",
            "Çıkış Gücü (12Ah)",
            "12A x 27,1V = 325,2VA (Tam Şarjda)",
            "Çıkış Gücü (18Ah)",
            "18A x 27,1V = 487,8VA (Tam Şarjda)",
            "Çalışma Sıcaklığı",
            "Ağırlık",
        ],
        AGParafudr: [
            "Alçak Gerilim Parafudr",
            "TEDAŞ-MLZ-2003-006.C ve TEDAŞ-MLZ-2022-077 şartnamelerine uygundur.",
            "Varistörler ve spark gap’in birlikte kullanıldığı hibrid teknoloji ile üstün koruma sağlanır.",
            "Kompakt tasarım sayesinde en dar panolarda bile rahatça kullanılabilir.",
            "Üzerindeki göstergeler ile arıza durumu izlenebilir.",
            "Kuru kontak çıkışı ile arıza durumu uzaktan izlenebilir.",
            "Yerli tasarım, yerli üretim",
            "5 yıl garanti",
            "TÜV belgelidir",
            "EN 61643-11 standardına göre A.G. Parafudr sınıfı T1+T2’dir.",
            "Elektriksel Özellikler",
            "Nominal Gerilimi (AC) (50/60HZ)",
            "En Yüksek Sürekli çalışma AC Gerilimi (L-N)",
            "Nominal Deşarj Akımı (8/20µs)",
            "En Yüksek Deşarj Akımı (8/20µs)",
            "Darbe Deşarj Akımı (10/350µs)",
            "Gerilim Koruma Seviyesi",
            "Tepki Süresi",
            "En Yüksek Sigorta",
            "Geçici Aşırı Gerilim -120 dk(l-N)",
            "Geçici Aşırı Gerilim -200ms (N-PE)",
            "Takip Akımı Sınırlama (N-PE)",

        ],
        Footer: [
            "İletişime Geçin",
            "Enerjinin üretim, iletim, dağıtım ve tüketim süreçlerinde müşterilerimize uçtan uca akıllı çözümler sunuyoruz",
            "Kurumsal",
            "Hakkımızda",
            "E-Mobility",
            "Elektirik Malzemeleri",
            "Enerji Depolama",
            "İletişim"
        ],
        EnergyMonitoring: [
            "Ölçme, Koruma, Kumanda",
            "Gerilim ve Frekans Koruma Rölesi (Loss of Main /LOM)   ",
            "Şehir şebekesini sürekli izleyerek oluşabilecek herhangi bir gerilim yada frekans bozulmasında enerji santrali ile elektrik şebekesini belirlenen süre içerisinde ayırır ve sistem nominal değerine döndüğünde elektrik üretim tesisinin tekrar devreye girmesini sağlar.",
            "KORUMA FONKSİYONLARI",
            "Ters Zamanlı Faz aşırı gerilimi",
            "Ters Zaman Faz düşük gerilimi",
            "Düşük frekans",
            "Aşırı frekans",
            "Tekrar Kapama",
            "Frekans Değişim Oranı (ROCOF)",
            "DONANIM",
            "Besleme Girişi ",
            "18-36V DC",
            "Dijital Girişler",
            "4 Dijital Giriş",
            "Dijital Çıkışlar ",
            "2 Dijital Çıkış",
            "Gerilim Girişi ",
            "4 Gerilim Girişi",
            "Haberleşme",
            "RS485 Haberleşme Portu",
            "Kesici Konum Bilgisi (Giriş-1)",
            "Remote Aktif/Pasif (Giriş-2) ",
            "Remote Açma Rölesi (Giriş-3)",
            "Remote Kapam Rölesi (Giriş-4)",
            "Servis Portu ",
            "Ön Panel ‘Set’, ‘Up’ ve ‘Down’ Butonları",
            "Ayarlanabilir Koruma Fonksiyonları",
            "Ayarlanabilir Tekrar Kapama Sayısı ve Süresi ",
            "Modbus RTU Haberleşme Protokolü",
            "Çıkış Test Menüsü ",
            "Olay kaydı ve Mesaj Görüntülenmesi ",
            "Ayarlanabilir Saat/Tarih",
            "KORUMA FONKSİYONLARI",
            "Ters Zamanlı Faz aşırı gerilimi (Bara)",
            "Ters Zamanlı Faz aşırı gerilimi (Hat)",
            "Ters Zamanlı nötr aşırı gerilim (Bara)",
            "Faz Dengesi gerilim koruması (Bus Bar)",
            "Ters Zaman Faz düşük gerilimi (Bara)",
            "Ters Zaman Faz düşük gerilimi (Hat)",
            "Ters Zaman Pozitif bileşen düşük gerilimi (Bara)",
            "Gerilim değişim oranı",
            "Düşük/Aşırı frekans",
            "Frekans Değişim Oranı (ROCOF)",
            "Vektör Kayması",
            "Aşırı akı",
            "Tekrar kapama",
            "Kesici izleme",
            "Senkronize kontrol",
            "Açma Devresi Denetimi",
            "Kapama Devresi Denetimi",
            "External Trip",
            "Trip lockout",
            "DONANIM",
            "Besleme Girişi	 24-230 VDC/VAC",
            "24-230 VDC/VAC",
            "Dijital Girişler ",
            "11 Dijital Giriş",
            "Dijital Çıkışlar ",
            "5 Dijital Çıkış",
            "Gerilim Girişi ",
            "5 Gerilim Girişi",
            "Haberleşme ",
            "RS485,Mikro USB, Ethernet* (Opsiyonel)",
            "Özellikler"
        ],
        Search: [
            "Ara",
            "Örneğin AG Pano, Transförmator, Enerji İzleme Rölesi",
            "Daha fazlası "
        ]
    }
}